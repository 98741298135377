import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { ParallaxDirective } from '../parallax.directive';
import { GlobalizationService } from '../shared/globalization/globalizationService';

@Component({
    selector: "legal-notice-component",
    templateUrl: "./legal-notice.component.html",
    styleUrls: ["./legal-notice.component.css"]
})
export class LegalNotice {

    constructor(private router: Router, public globService: GlobalizationService) {

    }
}