import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { ParallaxDirective } from '../parallax.directive';
import { GlobalizationService } from '../shared/globalization/globalizationService';

@Component({
    selector: "about-us-component",
    templateUrl: "./about-us.component.html",
    styleUrls: ["./about-us.component.css"]
})
export class AboutUs {

    constructor(private router: Router, public globService: GlobalizationService) {

    }
}