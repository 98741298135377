import { Directive, Input, ElementRef, HostListener } from '@angular/core';

@Directive({
    selector: '[appParallax]'
})
export class ParallaxDirective {

    @Input('ratio') parallaxRatio: number = 1;
    public initialTop: number = 0;
    private lastTimeStamp: number = 0;
    private timeStampDiff: number = 15;

    constructor(private eleRef: ElementRef) {
        //this.initialTop = this.eleRef.nativeElement.getBoundingClientRect().top;
        this.initialTop = window.scrollY;
        this.onWindowScroll(null);
        //alert(window.scrollY);
    }

    @HostListener("window:scroll", ["$event"])
    onWindowScroll(event) {
        //console.log();
        if (event != null && event.timeStamp - this.lastTimeStamp >= this.timeStampDiff) {
            this.lastTimeStamp = event.timeStamp;
            this.eleRef.nativeElement.style.top = (this.initialTop - (window.scrollY * this.parallaxRatio)) + 'px';
        }
    }

}