import { query } from '@angular/animations';
import { Component, OnInit, AfterViewInit, HostListener, ViewChild } from '@angular/core';
import { Location } from '@angular/common';
import { Router } from '@angular/router';
import { GlobalizationService } from '../shared/globalization/globalizationService';
//import { GearAnimator, GearRotator, Gear } from 'GearAnimatorModule';
/*import * as GearModule from 'GearAnimatorModule';*/
//import { Transformer, TransformData } from 'Transformer';

declare var GearAnimator: any;
declare var Throttler: any;
//declare var RelativeResizer: any;
//declare var Gear: any;
//declare var GearHoverer: any;
//declare var TransformData: any;
//declare var Transformer: any;
//declare var GearRotator: any;

@Component({
    selector: "main-component",
    templateUrl: "./main.component.html",
    styleUrls: ["./main.component.vars.css","./main.component.css"]
})
export class Main implements AfterViewInit  {

    private gearAnimator: any;
    private GearScrollThrottler: any;

    constructor(private router: Router, private location: Location, public globService: GlobalizationService) { 

        //scroll(this.onWindowScroll);

    }

    ngAfterViewInit() {
        this.gearAnimator = new GearAnimator(true);
        this.GearScrollThrottler = new Throttler([this.gearAnimator.RotateGears.bind(this.gearAnimator)]);
        var element = document.getElementsByClassName('PageContent');
        element[0].addEventListener("scroll", this.onWindowScroll.bind(this));
    }

    //@HostListener("scroll", ["$event"])
    onWindowScroll(event) {
        this.GearScrollThrottler.Update(event.target.scrollTop);
    } 

    navigateTo(destination:String) {
        const baseAddress = window.location.origin;
        const culture = this.globService.currentCulture;
        const url = `${baseAddress}/${culture}/${destination}`;
        const relativeUrl = `/${culture}/${destination}`;
        window.location.href = url;
    }
}