import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { ParallaxDirective } from '../../parallax.directive';
import { GlobalizationService } from '../../shared/globalization/globalizationService';

declare const RelativeResizer: any;
declare var $: any;

@Component({
    selector: "software-dev-service-component",
    templateUrl: "./software-dev-service.component.html",
    styleUrls: ["../services.component.css","software-dev-service.component.vars.css", "./software-dev-service.component.css"]
})
export class SoftwareDevService {

    constructor(private router: Router, public globService: GlobalizationService) {

    }

    ngOnInit() {
        //var header = $("#headerPanel");
        //new RelativeResizer($("#headerPanel"), $("#headerImg"));
    }
}