import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { ParallaxDirective } from '../../parallax.directive';
import { GlobalizationService } from '../../shared/globalization/globalizationService';

@Component({
    selector: "it-management-service-component",
    templateUrl: "./it-management-service.component.html",
    styleUrls: ["../services.component.css", "./it-management-service.component.vars.css", "./it-management-service.component.css"]
})
export class ITManagementService {

    constructor(private router: Router, public globService: GlobalizationService) {

    }
}