import { Component, OnInit } from '@angular/core';
import { Location } from '@angular/common';
import { ParallaxDirective } from '../parallax.directive';
import { GlobalizationService } from '../shared/globalization/globalizationService';

@Component({
    selector: "privacy-component",
    templateUrl: "./privacy.component.html",
    styleUrls: ["./privacy.component.css"]
})
export class Privacy {

    constructor(private location: Location, public globService: GlobalizationService) {

    }
}