

<section id="footer" class="overflow-hidden  bg-default-dark border-top-thin border-bottom-thin footer">
    
    <div class="container">
        <div class="row justify-content-end justify-content-sm-between text-center pt-3">
            <div class="col-md-1 col-sm-1 "></div>
            <div class="col-md-10 col-sm-10 ">
                <div>
                    <span class="font-weight-bold">{{this.globService.global_strings["Contact"]}}</span>
                </div>
                <div>
                    <a href="mailto:contact@zen-itc.com" target="_self">
                        <span>contact@zen-itc.com</span>
                    </a>
                </div>
                <div>
                    <span>+49 177 289 1560</span>
                </div>
                <div>
                    <span>Avd. Països Catalans 28, 2-1</span>
                </div>
                <div>
                    <span>08380 Malgrat de Mar</span>
                </div>
            </div>
            <div class="col-md-1 col-sm-1"></div>
        </div>

        <div class="row justify-content-end justify-content-sm-between ">
            <div class="@*col-md-2 col-sm-4*@  text-muted text-align-start mr-auto">
                &copy; 2020 - Zen-IT
            </div>
            <div class="@*col-md-3 col-sm-1*@">
            </div>
            <div class="@*col-md-5 col-sm-7*@ text-align-end">
                <a href="/{{this.globService.currentCulture}}/legal-notice">{{this.globService.global_strings["Legal_Notice"]}}</a>
                |
                <a href="/{{this.globService.currentCulture}}/privacy">{{this.globService.global_strings["Privacy"]}}</a>
                |
                <a href="/{{this.globService.currentCulture}}/cookies">{{this.globService.global_strings["Cookies"]}}</a>

            </div>
        </div>
    </div>
</section>
