


<section id="header-section" class="section header header-section header-section-top parallax-n75">


    <div class="header w-100 position-absolute bg-default-light">

        <div class="header-img shadow-img header-bg1">
        </div>

        <div class="position-absolute header-panel-container header-panel-container-animation">
            <div class="header-panel-box transition-ease-0_4 header-panel-box-animation">
                <div class="panel-text-block">
                    <div class="header-title text-align-center font-s-50 header-panel-title-animation">IT Management</div>
                    <div class="panel-text-intro-1 font-s-20 header-panel-subtitle-animation text-align-center">Verbessern Sie Ihre Performance!</div>
                </div>
            </div>
        </div>
    </div>



</section>

<section id="mid-section" class="section-default overflow-hidden bg-default-dark border-top-thin border-bottom-thin d-flex align-items-center justify-content-center">


    <div>
        <div class="container w-100 mt-150">
            <div class="row">
                <div class="col-lg-6 col-sm-12">
                    <div vpc-inside="transition-ease-0_8" vpc-outside="transition-ease-0_8 hidden-left-20" vpc-offset="60">
                        <h1 class="container-title-text">
                            IT Management
                        </h1>
                    </div>

                    <div vpc-inside="transition-ease-0_8" vpc-outside="hidden-bot-30 transition-ease-0_8" vpc-offset="120">
                        <p tw-wrap="span" vpc-inside="transition-ease-1_2" vpc-outside="hidden-left-20 transition-ease-1_2" vpc-offset="60">
                            Technology ("science of craft", from Greek τέχνη, techne, "art, skill, cunning of hand"; and -λογία, -logia[2]) is the sum of techniques, skills, methods, and processes used in the production of goods or services or in the accomplishment of objectives, such as scientific investigation. Technology can be the knowledge of techniques, processes, and the like, or it can be embedded in machines to allow for operation without detailed knowledge of their workings. Systems (e.g. machines) applying technology by taking an input, changing it according to the system's use, and then producing an outcome are referred to as technology systems or technological systems.

                            <br><br>The simplest form of technology is the development and use of basic tools. The prehistoric discovery of how to control fire and the later Neolithic Revolution increased the available sources of food, and the invention of the wheel helped humans to travel in and control their environment. Developments in historic times, including the printing press, the telephone, and the Internet, have lessened physical barriers to communication and allowed humans to interact freely on a global scale.

                        </p>
                    </div>
                </div>

                <div class="col-lg-6 col-sm-12 align-self-center " vpc-inside="transition-ease-1_2" vpc-outside="transition-ease-1_2 hidden-right-30" vpc-offset="260">
                    <img class="col-img shadow-img" src="/img/Images/stock-photo-business-strategy-plan-over-ladder-leading-to-success-356357318.jpg" />
                </div>
            </div>

        </div>
    </div>

</section>