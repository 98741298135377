import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';

import { Main } from './main/main.component';
import { Services } from './services/services.component';
import { SoftwareDevService } from './services/software-dev/software-dev-service.component';
import { ITManagementService } from './services/it-management/it-management-service.component';
import { ITConsultingService } from './services/it-consulting/it-consulting-service.component';
import { Contact } from './contact/contact.component';
import { AboutUs } from './about_us/about-us.component';
import { Privacy } from './privacy/privacy.component';
import { LegalNotice } from './legal-notice/legal-notice.component';
import { Cookies } from './cookies/cookies.component';

const routes: Routes = [
    { path: getLocale() + "", component: Main },
    { path: getLocale() + "/services/software-development", component: Services },
    { path: getLocale() + "/services/it-management", component: Services },
    { path: getLocale() + "/services/it-consulting", component: Services },
    { path: getLocale() + "/privacy", component: Privacy },
    { path: getLocale() + "/legal-notice", component: LegalNotice },
    { path: getLocale() + "/cookies", component: Cookies }];

@NgModule({
  //imports: [RouterModule.forRoot(routes)],
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule]
})
export class AppRoutingModule {

    constructor() {

        //routes[1].path = "en-US/" + routes[1].path;
        //console.log(routes);
        //console.log("router called");
    }
}

function getLocale() {

    var path = window.location.pathname.split("/");
    //console.log(path[1]);�
    //console.log(window.location.pathname);

    return path[1];
}