import { query } from '@angular/animations';
import { Component, OnInit, AfterViewInit, HostListener, ViewChild } from '@angular/core';
import { Router } from '@angular/router';
import { GlobalizationService } from '../shared/globalization/globalizationService';


@Component({
    selector: "footer-component",
    templateUrl: "./footer.component.html",
    styleUrls: ["./footer.component.css"]
})
export class Footer implements AfterViewInit {


    constructor(private router: Router, public globService: GlobalizationService) {

    }

    ngAfterViewInit() {

    }
}