import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { Location } from '@angular/common';

@Injectable()
export class GlobalizationService {

    public global_strings: string = "";
    public local_strings: string = "";

    public currentCulture: string = "";

    constructor(private location: Location) {
        this.global_strings = JSON.parse(document.getElementById("global_strings").getAttribute("value"));
        this.local_strings = JSON.parse(document.getElementById("local_strings").getAttribute("value"));
        
        this.currentCulture = location.path().split('/')[1];
    }


}