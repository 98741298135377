

<section id="section-contact" class="section-default overflow-hidden  bg-default-dark border-top-thin border-bottom-thin d-flex align-items-center justify-content-center" >

    

    
    <div class="container w-100 ">
        <div class="row">
            <div vpc-inside="transition-ease-0_8" vpc-outside="transition-ease-0_8 hidden-left-20" vpc-offset="40" vpc-transfer-to-children="">
                <h1 class="container-title-text mb-75">
                    {{this.globService.global_strings["Contanct_Title"]}}
                </h1>
            </div>
        </div>
        <div class="row">
            <div class="w-100" >
                <form [formGroup]="form" (ngSubmit)="send()">
                    <div class="form-group w-100 align-items-center justify-content-center">
                        <div class="row">
                            <div class="col-lg-6 d-table-cell">
                            <div class="d-flex mb-4">
                                <input required class="transition-ease-1_0 position-relative position-0 " formControlName="name"  vpc-inside="input-visible" vpc-offset="40" vpc-outside="input-hidden left-75 hidden bottom-75" type="text" 
                                       placeholder=""
                                       id="name" name="name" />
                            </div>
                            <div class="d-flex mb-4">
                                <input class="transition-ease-1_0 position-relative position-0" formControlName="email" vpc-inside="input-visible" vpc-offset="40" vpc-outside="input-hidden left-75 hidden bottom-75" type="email" 
                                       id="email" name="email"/>
                            </div>
                        </div>
                        <div class="col-lg-6 d-table-cell">
                            <div class="d-flex mb-4">
                                <input class="transition-ease-1_0 position-relative position-0" formControlName="phone"  vpc-inside="input-visible" vpc-offset="40" vpc-outside="input-hidden right-75 hidden bottom-75" type="text" 
                                       id="phone" name="phone" />
                            </div>
                            <div class="d-flex mb-4">
                                <input  class="transition-ease-1_0 position-relative position-0" formControlName="company" vpc-inside="input-visible" vpc-offset="40" vpc-outside="input-hidden right-75 hidden bottom-75" type="text" 
                                        id="company" name="company"  />
                            </div>                            
                        </div>
                        </div>
                        <div classa="row">
                            <div class="d-flex mb-4">
                                <input  class="transition-ease-1_0 position-relative position-0" formControlName="subject"  vpc-inside="input-visible position-0" vpc-outside="input-hidden bottom-75 hidden"  vpc-offset="20" type="text" 
                                       id="subject" name="subject"  />
                            </div>   
                            <div class="d-flex mb-4">
                                <textarea class="transition-ease-1_0 position-relative " formControlName="message" vpc-inside="input-visible position-0" vpc-outside="textarea-hidden bottom-75 hidden" vpc-offset="20" 
                                            id="message" name="message">
                                </textarea>
                            </div>
                        </div>
                        <button class="buttons transition-ease-1_0" type="submit" vpc-inside="visible transition-ease-1_0 position-0" vpc-outside="left-75 transition-ease-1_0 hidden"  vpc-offset="40" css-on-click="transition-ease-0_2"> Send </button>
                    </div>
                </form>
            </div>
        </div>
    </div>
    <a id="sendMailLink" href="mailto:123@123.com" hidden>This is the email link</a>
</section>