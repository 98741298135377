import { Component, OnInit, ViewChild } from '@angular/core';
import { Router } from '@angular/router';
import { GlobalizationService } from '../shared/globalization/globalizationService';
import { FormBuilder, FormGroup } from '@angular/forms';

@Component({
    selector: "contact-component",
    templateUrl: "./contact.component.html",
    styleUrls: ["./contact.component.css"]
})
export class Contact implements OnInit {

    form: FormGroup;

    ngOnInit(): void {
        this.buildForm();

        document.getElementById('name').setAttribute("placeholder", this.globService.global_strings["Contact_Placeholder_Name"]);
        document.getElementById('email').setAttribute("placeholder", this.globService.global_strings["Contact_Placeholder_Email"]);
        document.getElementById('phone').setAttribute("placeholder", this.globService.global_strings["Contact_Placeholder_Phone"]);
        document.getElementById('company').setAttribute("placeholder", this.globService.global_strings["Contact_Placeholder_Company"]);
        document.getElementById('subject').setAttribute("placeholder", this.globService.global_strings["Contact_Placeholder_Subject"]);
        document.getElementById('message').setAttribute("placeholder", this.globService.global_strings["Contact_Placeholder_Message"]);

        document.getElementById('sendMailLink').setAttribute('href', "mailto:" + this.globService.global_strings["Contact_Email_ZenIT"]);
    }

    constructor(private formBuilder: FormBuilder, private router: Router, public globService: GlobalizationService) {

    }

    send(): void {
        const { name, email, phone, company, subject, message } = this.form.value;
        //alert(`Name: ${name}, Email: ${email}, Phone: ${phone}, Company: ${company}, Subject: ${subject}, Message: ${message} `);


        let mailTo = "mailto:" + this.globService.global_strings["Contact_Email_ZenIT"];

        let sendMail = document.getElementById('sendMailLink');

        let contactData = this.globService.global_strings["Contact_Message_Body_Contact_Data"];
        contactData = contactData.replace("@Name@", name);
        contactData = contactData.replace("@Company@", company);
        contactData = contactData.replace("@Phone@", phone);
        contactData = contactData.replace("@Email@", email);

        let messageBody = '';
        messageBody += message;
        messageBody += "\n\n"+contactData + "\n\n";



        sendMail.setAttribute('href', mailTo + "?subject=" + encodeURIComponent(subject) + "&body=" + encodeURIComponent(messageBody));

        setTimeout(() => sendMail.click(), 1000);
        //sendMail.click();
        
    }

    private buildForm(): void {
        this.form = this.formBuilder.group({
            name: this.formBuilder.control(null),
            email: this.formBuilder.control(null),
            phone: this.formBuilder.control(null),
            company: this.formBuilder.control(null),
            subject: this.formBuilder.control(null),
            message: this.formBuilder.control(null),
        });
    }
}