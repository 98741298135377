


    <section id="header-section" class="section header header-section header-section-top parallax-n75">


        <div id="GearsBG" class="header header-bg header-bg2 w-100">

            <div class="gear-container-default gear-container-2 gear-container-shadow">
                <div class="gear-glow-2 gear-sizes-2 gear-default   position-absolute"></div>
                <div class="gear-mask-2 gear-sizes-2  gear-default position-absolute">
                    <div class="gear-foreground-2  gear-sizes-2 gear-default"></div>
                </div>
                <div class="gear-outline-2 gear-sizes-2  gear-default position-absolute"></div>
                <div class="gear-mid-2 gear-sizes-2 gear-default position-absolute"></div>
                <div class="gear-text-default gear-text-2 ">
                    {{this.globService.local_strings["main_business"]}}
                </div>
            </div>
            <div class="gear-container-default gear-container-1 gear-container-shadow">
                <div class="gear-glow-1 gear-sizes-1 gear-default   position-absolute"></div>
                <div class="gear-mask-1 gear-sizes-1  gear-default position-absolute">
                    <div class="gear-foreground-1  gear-sizes-1 gear-default"></div>
                </div>
                <div class="gear-outline-1 gear-sizes-1  gear-default position-absolute"></div>
                <div class="gear-mid-1 gear-sizes-1 gear-default position-absolute"></div>
                <div class="gear-text-default ">
                    {{this.globService.local_strings["main_performance"]}}
                </div>
            </div>
            <div class="gear-container-default gear-container-3 gear-container-shadow">
                <div class="gear-glow-3 gear-sizes-3 gear-default   position-absolute"></div>
                <div class="gear-mask-3 gear-sizes-3  gear-default position-absolute">
                    <div class="gear-foreground-3  gear-sizes-3 gear-default"></div>
                </div>
                <div class="gear-outline-3 gear-sizes-3  gear-default position-absolute"></div>
                <div class="gear-mid-3 gear-sizes-3 gear-default position-absolute"></div>
                <div class="gear-text-default gear-text-3 ">
                    {{this.globService.local_strings["main_it"]}}
                </div>
            </div>

            <div id="panel-header-container-1" class="panel-container ">
                <div id="panel-1-bg" class="panel-1 panel-bg-1 panel-bg-animation-1">
                </div>
                <div id="panel-1-border" class="panel-1 panel-border-1 panel-border-animation-1">
                    <div class="panel-text-block panel-text-block-1">

                        <div id="panel-1-txt1" class="panel-text-intro-1 panel-text-animation-intro-1">
                            {{this.globService.local_strings["main_titlePanel_Line1"]}}
                        </div>
                        <div id="panel-1-txt2" class="panel-text-intro-1 panel-text-animation-intro-2">
                            {{this.globService.local_strings["main_titlePanel_Line2"]}}
                        </div>
                        <div id="panel-1-txt3" class="panel-text-intro-1 panel-text-animation-intro-3">
                            {{this.globService.local_strings["main_titlePanel_Line3"]}}
                        </div>
                        <div id="panel-1-title1" class="panel-text-title-1 panel-title-animation-1">
                            {{this.globService.local_strings["main_titlePanel_Title"]}}
                        </div>
                        <div id="more-info-btn" class="btn-partial-outline-container">
                            <div id="panel-1-button" class="btn btn-partial-outline btn-information panel-button-animation-1">
                                <a href="#section-main-message">{{this.globService.local_strings["main_titlePanel_InfoButton"]}}</a>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

        </div>

    </section>
    <!--<section class="section-default bg-default-light shadow-section">-->

<section id="mid-section" class="section-default overflow-hidden  bg-default-dark border-top-thin border-bottom-thin ">

    <!--<div class='device-check d-xl-block d-lg-none d-md-none d-sm-none d-none' >XL</div>
    <div class='device-check d-xl-none d-lg-block d-md-none d-sm-none d-none' >LG</div>
    <div class='device-check d-xl-none d-lg-none d-md-block d-sm-none d-none' >MD</div>
    <div class='device-check d-xl-none d-lg-none d-md-none d-sm-block d-none' >SM</div>
    <div class='device-check d-xl-none d-lg-none d-md-none d-sm-none d-block' >XS</div>-->


    <div class="container w-100 px-3 mt-150">

        <div class="row justify-content-center justify-content-sm-between ">

            <!--1st Card-->
            <div class=" col-md-6 col-lg-4 offset-md-0 offset-lg-0">
                <div class="card card-h-500 shadow-outside" (click)="navigateTo('services/software-development')">

                    <div class="card-icon-container ">
                        <div class="icon icon-code card-icon">

                        </div>
                    </div>
                    <div class="card-text-block text-align-start">
                        <p class=" card-text-title " vpc-inside="show-card-content-mid" vpc-outside="hide-card-content-mid" vpc-transfer-to-children="">
                            <span>{{this.globService.local_strings["main_card_dev_title"]}}</span>
                        </p>
                        <div class="card-text-body">
                            <p vpc-inside="show-card-content-left" vpc-offset="0" vpc-outside="hide-card-content-left" vpc-transfer-to-children="" tw-wrap="span" tw-class="show-card-content-left">
                                {{this.globService.local_strings["main_card_dev_first"]}}
                            </p>
                            <p vpc-inside="show-card-content-left" vpc-outside="hide-card-content-left" vpc-transfer-to-children="" tw-wrap="span" tw-class="show-card-content-left">
                                {{this.globService.local_strings["main_card_dev_second"]}}
                            </p>
                            <p class="position-absolute bottom-0 font-weight-bold" vpc-inside="show-card-content-mid" vpc-outside="hide-card-content-mid" vpc-transfer-to-children="" >
                                <a>{{this.globService.global_strings["Learn_More"]}}</a>
                            </p>
                        </div>
                    </div>
                </div>
            </div>

            
            <!--2nd Card-->
            <div class=" col-md-6 col-lg-4 offset-md-0 offset-lg-0">
                <div class="card card-h-500 shadow-outside" (click)="navigateTo('services/it-management')">

                    <div class="card-icon-container ">
                        <div class="icon icon-quality card-icon">
                             
                        </div>
                    </div>
                    <div id="right-index-card-content" class="card-text-block text-align-center">
                        <p class="card-text-title " vpc-transfer-to-children="" vpc-inside="show-card-content-mid" vpc-outside="hide-card-content-mid">
                            <span>{{this.globService.local_strings["main_card_management_title"]}}</span>
                        </p>
                        <div class="card-text-body ">
                            <p  vpc-inside="show-card-content-mid" vpc-outside="hide-card-content-mid" vpc-transfer-to-children=""  tw-wrap="span" tw-class="show-card-content-mid">
                                {{this.globService.local_strings["main_card_management_first"]}}
                            </p>
                            <p  vpc-inside="show-card-content-mid" vpc-outside="hide-card-content-mid" vpc-transfer-to-children=""  tw-wrap="span" tw-class="show-card-content-mid">
                                {{this.globService.local_strings["main_card_management_second"]}}
                            </p>
                            <p class="position-absolute bottom-0 right-0 mr-15 font-weight-bold width-85" vpc-inside="show-card-content-mid" vpc-outside="hide-card-content-mid" vpc-transfer-to-children="" >
                                <a>{{this.globService.global_strings["Learn_More"]}}</a>
                            </p>
                        </div>
                    </div>
                </div>
            </div>

            <!--3d Card-->
            <div class=" col-md-6 col-lg-4 offset-md-0 offset-lg-0">
                <div class="card card-h-500 shadow-outside" (click)="navigateTo('services/it-consulting')">

                    <div class="card-icon-container">
                        <div class="icon icon-performance card-icon">

                        </div>
                    </div>
                    <div id="mid-index-card-content" class="card-text-block text-align-end">
                        <p class="card-text-title" vpc-inside="show-card-content-mid" vpc-outside="hide-card-content-mid" vpc-transfer-to-children="">
                            <span>{{this.globService.local_strings["main_card_cons_title"]}}</span>
                        </p>
                        <div class="card-text-body">
                            <p vpc-inside="show-card-content-right" vpc-outside="hide-card-content-right" vpc-transfer-to-children="" tw-wrap="span" tw-class="show-card-content-rigth">
                                {{this.globService.local_strings["main_card_cons_first"]}}
                            </p>
                            <p vpc-inside="show-card-content-right" vpc-outside="hide-card-content-right" vpc-transfer-to-children="" tw-wrap="span" tw-class="show-card-content-rigth">
                                {{this.globService.local_strings["main_card_cons_second"]}}
                            </p>
                            <p class="position-absolute bottom-0 width-85 font-weight-bold" vpc-inside="show-card-content-mid" vpc-outside="hide-card-content-mid" vpc-transfer-to-children="" >
                                <a>{{this.globService.global_strings["Learn_More"]}}</a>
                            </p>
                        </div>
                    </div>
                </div>
            </div>

        </div> <!--Row-->
    </div>

</section>

    <section id="section-main-message" class="section-default  overflow-hidden bg-default-light d-flex align-items-center justify-content-center">
        <div>
            <div class="container w-100 mt-150">
                <div class="row">
                    <div class="col-lg-6 col-sm-8">
                        <div vpc-inside="transition-ease-0_8" vpc-outside="transition-ease-0_8 hidden-left-20" vpc-offset="40" vpc-transfer-to-children="">
                            <h1 class="container-title-text">
                                {{this.globService.local_strings["main_message_information_technology"]}}
                            </h1>
                        </div>
                        <div vpc-inside="transition-ease-0_8" vpc-outside="transition-ease-0_8 hidden-left-20" vpc-offset="80" vpc-transfer-to-children="">
                            <h1 class="container-title-text">
                                {{this.globService.local_strings["main_message_business"]}}
                            </h1>
                        </div>
                        <div vpc-inside="transition-ease-0_8" vpc-outside="transition-ease-0_8 hidden-left-20" vpc-offset="120" vpc-transfer-to-children="">
                            <h1 class="container-title-text">
                                {{this.globService.local_strings["main_message_performance"]}}
                            </h1>
                        </div>
                        <div  vpc-inside="transition-ease-0_8" vpc-outside="hidden-bot-30 transition-ease-0_8" vpc-offset="140" vpc-transfer-to-children="">
                            <p tw-wrap="span" vpc-inside="transition-ease-1_2" vpc-outside="hidden-left-20 transition-ease-1_2" vpc-offset="10" vpc-transfer-to-children="">
                                {{this.globService.local_strings["main_message_part1"]}}
                                <br><br>{{this.globService.local_strings["main_message_part2"]}}
                            </p>
                            
                        </div>
                        <div>
                            <ol vpc-inside="transition-ease-0_8" vpc-outside="hidden-left-20 transition-ease-0_8" vpc-offset="20" vpc-transfer-to-children="">
                                <li>{{this.globService.local_strings["main_message_performance_list_1"]}}</li>
                                <li>{{this.globService.local_strings["main_message_performance_list_2"]}}</li>
                                <li>{{this.globService.local_strings["main_message_performance_list_3"]}}</li>
                                <li>{{this.globService.local_strings["main_message_performance_list_4"]}}</li>
                                <li>{{this.globService.local_strings["main_message_performance_list_5"]}}</li>
                                <li>{{this.globService.local_strings["main_message_performance_list_6"]}}</li>
                            </ol>
                        </div>
                    </div>

                    <div class="col-lg-6 col-sm-12 align-self-center " vpc-inside="transition-ease-1_2" vpc-outside="transition-ease-1_2 hidden-right-30" vpc-offset="260" vpc-transfer-to-children="">
                        <img class="col-img shadow-img" src="/img/Images/stock-photo-the-double-exposure-image-of-the-business-man-standing-back-during-sunrise-overlay-with-cityscape-769705507.jpg" />
                    </div>
                </div>

            </div>
        </div>
    </section>


    

    
