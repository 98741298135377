

<section class="header h-250 min-h-250">

</section>

<section id="header-section" class="section p-4">



    <div *ngIf="globService.currentCulture == 'de-DE'" class="container shadow-outside p-5 border-radius-25 bg-default-light mb-75">

        <h1 class="container-title-text">I. Datenschutz- und Datenschutzrichtlinien</h1>
        <p>Unter Beachtung der geltenden Rechtsvorschriften, <span class="blanc-space ng-binding">Zen-IT</span> (im Folgenden auch die Website) dazu, die erforderlichen technischen und organisatorischen Maßnahmen zu treffen, die dem Risiko der erhobenen Daten angemessen sind.</p>
        <h5><strong>In dieser Datenschutzerklärung enthaltene Gesetze</strong></h5>
        <p>Diese Datenschutzerklärung ist an die aktuellen spanischen und europäischen Vorschriften zum Schutz personenbezogener Daten im Internet angepasst. Im Einzelnen beachtet es die folgenden Regeln:</p>
        <ul>
            <li>Verordnung (EU) 2016/679 des Europäischen Parlaments und des Rates vom 27. April 2016 zum Schutz natürlicher Personen bei der Verarbeitung personenbezogener Daten und zum freien Datenverkehr (RGPD).</li>
            <li>Organisches Gesetz 3/2018 vom 5. Dezember über den Schutz personenbezogener Daten und die Gewährleistung digitaler Rechte (LOPD-GDD).</li>
            <li>Königliches Dekret 1720/2007 vom 21. Dezember, das die Verordnungen zur Entwicklung des Organgesetzes 15/1999 vom 13. Dezember über den Schutz personenbezogener Daten (RDLOPD) genehmigt.</li>
            <li>LaGesetz 34/2002 vom 11. Juli über Dienstleistungen der Informationsgesellschaft und des elektronischen Geschäftsverkehrs (LSSI-CE).</li>
        </ul>
        <h5><strong>Identität des Verantwortlichen für die Verarbeitung personenbezogener Daten</strong></h5>
        <p ng-show="data.claseTitular == 'person'"> Der Verantwortliche für die Verarbeitung der in <strong> Zen-IT </strong> erhobenen personenbezogenen Daten ist: <strong> Christian Kunze </strong>, mit NIF: <strong> X-2972819-T </strong> (im Folgenden für die Behandlung verantwortlich). Die Kontaktdaten lauten wie folgt: </p>
        <p>
            Adresse: <strong> Avd. dels Paisos Catalans, 28, 2-1, 08380 Malgrat de Mar </strong>
        </p>
        <p>
            Kontakttelefon: <strong> +49 177 289 1560 </strong>
        </p>
        <p>
            Kontakt-E-Mail: <strong> contact@zen-itc.com </strong>
        </p>
        <h5> <strong> Persönlicher Datensatz </strong> </h5>
        <p> In Übereinstimmung mit den Bestimmungen der RGPD (Reglamento General de Protección de Datos und der LOPD-GDD (Ley Organica de Protección de datos y Garantía de Derechos Digitales) teilen wir Ihnen mit, dass die von <span class="blanc-space ng-binding"> Zen-IT </span> erhobenen personenbezogenen Daten durch die erweiterten Formulare auf seinen Seiten werden in unsere Datei aufgenommen und behandelt, um die Verpflichtungen zwischen <span class="blanc-space ng-binding"> Zen-IT </span> und dem Benutzer zu erleichtern, zu beschleunigen und zu erfüllen oder die Aufrechterhaltung der Beziehung, die in den ausgefüllten Formularen festgelegt ist, oder um einer Anfrage oder Anfrage nachzukommen. Ebenso wird in Übereinstimmung mit den Bestimmungen der RGPD und der LOPD-GDD, sofern nicht die in Artikel 30.5 der RGPD vorgesehene Ausnahme anwendbar ist, ein Verzeichnis der Behandlungstätigkeiten geführt, in dem entsprechend ihrem Zweck die durchgeführten Behandlungstätigkeiten aufgeführt sind und die anderen in der RGPD festgelegten Umstände. </p>
        <h5> <strong> Grundsätze für die Verarbeitung personenbezogener Daten </strong> </h5>
        <p> Die Verarbeitung der personenbezogenen Daten des Benutzers unterliegt den folgenden Grundsätzen, die in Artikel 5 der RGPD und in Artikel 4 ff. des Organgesetzes 3/2018 vom 5. Dezember über den Schutz personenbezogener Daten und die Garantie festgelegt sind der digitalen Rechte: </p>
        <ul>
            <li>Prinzip der Rechtmäßigkeit, Loyalität und Transparenz: Die Zustimmung des Benutzers ist jederzeit erforderlich, vor vollständig transparenter Information über die Zwecke, für die die personenbezogenen Daten erhoben werden. </li>
            <li>Prinzip der Zweckbindung: Personenbezogene Daten werden für bestimmte, eindeutige und legitime Zwecke erhoben. </li>
            <li>Prinzip der Datenminimierung: Es werden nur die personenbezogenen Daten erhoben, die für die Zwecke, für die sie verarbeitet werden, unbedingt erforderlich sind. </li>
            <li>Prinzip der Richtigkeit: Personenbezogene Daten müssen korrekt und immer aktuell sein. </li>
            <li>Prinzip der Begrenzung der Aufbewahrungsfrist: Personenbezogene Daten werden nur so aufbewahrt, dass die Identifizierung des Benutzers für die für die Zwecke ihrer Verarbeitung erforderliche Zeit möglich ist. </li>
            <li>Prinzip der Integrität und Vertraulichkeit: Personenbezogene Daten werden so behandelt, dass ihre Sicherheit und Vertraulichkeit gewährleistet ist. </li>
            <li>Grundsatz der proaktiven Verantwortung: Der Datenverantwortliche ist dafür verantwortlich, dass die oben genannten Grundsätze eingehalten werden.</li>
        </ul>
        <h5> <strong> Kategorien personenbezogener Daten </strong> </h5>
        <p ng-show="data.datosPersonales == 'opcionA'"> Die Kategorien von Daten, die in <span class="blanc-space ng-binding"> Zen-IT </span> behandelt werden, sind nur identifizierende Daten . In keinem Fall werden besondere Kategorien personenbezogener Daten im Sinne von Artikel 9 RGPD verarbeitet. </p>
        <h5> <strong> Rechtsgrundlage für die Verarbeitung personenbezogener Daten </strong> </h5>
        <p> Die Rechtsgrundlage für die Verarbeitung personenbezogener Daten ist die Einwilligung. <span class="blanc-space ng-binding"> Zen-IT </span> verpflichtet sich, die ausdrückliche und nachprüfbare Zustimmung des Nutzers zur Verarbeitung seiner personenbezogenen Daten für einen oder mehrere bestimmte Zwecke einzuholen. </p>
        <p> Der Benutzer hat das Recht, seine Einwilligung jederzeit zu widerrufen. Der Widerruf der Einwilligung ist ebenso einfach wie die Erteilung. In der Regel wird der Widerruf der Zustimmung nicht die Nutzung der Website bedingt. </p>
        <p> In den Fällen, in denen der Benutzer seine Daten über Formulare zur Verfügung stellen muss oder kann, um Anfragen zu stellen, Informationen anzufordern oder aus Gründen im Zusammenhang mit dem Inhalt der Website, wird er benachrichtigt, falls die Vervollständigung einer von Sie ist obligatorisch, da sie für die korrekte Entwicklung der durchgeführten Operation unerlässlich sind. </p>
        <h5> <strong> Zwecke der Verarbeitung, für die die personenbezogenen Daten bestimmt sind </strong> </h5>
        <p> Personenbezogene Daten werden von <span class="blanc-space ng-binding"> Zen-IT </span> gesammelt und verwaltet, um die zwischen der Website und dem Benutzer eingegangenen Verpflichtungen oder die Wartung zu erleichtern, zu beschleunigen und zu erfüllen der Beziehung, die in den Formularen festgelegt ist, die dieser ausfüllt oder um einer Anfrage oder Anfrage nachzugehen. </p>
        <p> Ebenso können die Daten für eine kommerzielle Personalisierung, betriebliche und statistische Zwecke sowie für Aktivitäten des Unternehmenszwecks von <span class="blanc-space ng-binding"> Zen-IT </span> verwendet werden sowie für die Extraktion, Speicherung von Daten und Marketingstudien, um die dem Benutzer angebotenen Inhalte anzupassen sowie die Qualität, den Betrieb und die Navigation der Website zu verbessern. </p>
        <p> Zum Zeitpunkt der Erhebung der personenbezogenen Daten wird der Nutzer über den spezifischen Zweck oder die Zwecke der Verarbeitung informiert, für die die personenbezogenen Daten verwendet werden; das heißt, die Verwendung oder Verwendungen, die den gesammelten Informationen gegeben werden. </p>
        <h5> <strong> Aufbewahrungsfristen personenbezogener Daten </strong> </h5>
        <p> Personenbezogene Daten werden nur für die für ihre Verarbeitung erforderliche Mindestzeit und in jedem Fall nur während des folgenden Zeitraums gespeichert: <span class="blanc-space ng-binding"> 18 Monaten</span> , oder bis der Benutzer die Löschung beantragt. </p>
        <p> Zum Zeitpunkt der Erhebung der personenbezogenen Daten wird der Nutzer über die Aufbewahrungsfrist der personenbezogenen Daten oder, falls dies nicht möglich ist, über die Kriterien zur Festlegung dieser Frist informiert. </p>
        <h5> <strong> Empfänger personenbezogener Daten </strong> </h5>
        <p ng-show="data.datosCompartidos == 'Yes'"> Die personenbezogenen Daten des Benutzers werden an die folgenden Empfänger oder Kategorien von Empfängern weitergegeben: </p>
        <ul>
            <li>Google Ireland Limited: Gordon House, Barrow Street, Dublin 4, Irland </li>
            <li>STRATO AG: Pascalstraße 10, 10587 Berlin, Deutschland </li>
        </ul>
        <p ng-show="data.datosCompartidos == 'Ja'"> Falls der Verantwortliche beabsichtigt, personenbezogene Daten an ein Drittland oder eine internationale Organisation zu übermitteln, wird der Benutzer zum Zeitpunkt der Erhebung der personenbezogenen Daten über das Drittland oder die internationale Organisation, an die die Daten übermittelt werden sollen, sowie über das Vorliegen oder Fehlen eines Angemessenheitsbeschlusses der Kommission informiert werden. </p>
        <h5> <strong> Personenbezogene Daten von Minderjährigen </strong> </h5>
        <p> Unter Beachtung der Bestimmungen der Artikel 8 der RGPD und 7 des Organgesetzes 3/2018 vom 5. Dezember über den Schutz personenbezogener Daten und die Gewährleistung digitaler Rechte können nur Personen über 14 Jahren ihre Zustimmung zu den rechtmäßige Verarbeitung Ihrer personenbezogenen Daten durch <span class="blanc-space ng-binding"> Zen-IT </span>. Bei Minderjährigen unter 14 Jahren ist für die Behandlung die Zustimmung der Eltern oder Erziehungsberechtigten erforderlich, die nur insoweit zulässig ist, als sie dies genehmigt haben. </p>
        <h5> <strong> Geheimhaltung und Sicherheit personenbezogener Daten </strong> </h5>
        <p>
            <span class="blanc-space ng-binding"> Zen-IT </span> verpflichtet sich, die notwendigen technischen und organisatorischen Maßnahmen zu treffen, die dem Risiko der erhobenen Daten angemessen sind, um die Sicherheit personenbezogener Daten und Vermeidung von versehentlicher oder rechtswidriger Zerstörung, Verlust oder Änderung von übertragenen, gespeicherten oder anderweitig verarbeiteten personenbezogenen Daten oder unbefugter Kommunikation oder unbefugtem Zugriff auf diese Daten.
        </p>
        <p ng-show="hasSSL == 'Yes'" class=""> Die Website verfügt über ein SSL-Zertifikat (Secure Socket Layer), das gewährleistet, dass personenbezogene Daten wie die Übertragung sicher und vertraulich übertragen werden von Daten zwischen dem Server und dem Benutzer, und im Feedback vollständig verschlüsselt oder verschlüsselt. </p>
        <p> Da <span class="blanc-space ng-binding"> Zen-IT </span> jedoch nicht garantieren kann, dass das Internet nicht durchsetzbar ist oder dass Hacker oder andere Personen, die in betrügerischer Absicht auf personenbezogene Daten zugreifen, vollständig fehlen, übernimmt der Datenverantwortliche verpflichtet sich, den Benutzer unverzüglich zu benachrichtigen, wenn eine Verletzung der Sicherheit personenbezogener Daten vorliegt, die voraussichtlich ein hohes Risiko für die Rechte und Freiheiten natürlicher Personen mit sich bringt. Gemäß den Bestimmungen von Artikel 4 des RGPD wird als Verletzung der Sicherheit personenbezogener Daten jede Verletzung der Sicherheit verstanden, die die Zerstörung, den Verlust oder die versehentliche oder rechtswidrige Änderung der übermittelten, aufbewahrten oder anderweitig verarbeiteten personenbezogenen Daten oder die unbefugte Kommunikation verursacht oder Zugriff auf diese Daten. </p>
        <p> Personenbezogene Daten werden von der für die Behandlung verantwortlichen Person vertraulich behandelt, die sich verpflichtet, darüber zu informieren und durch eine gesetzliche oder vertragliche Verpflichtung zu gewährleisten, dass diese Vertraulichkeit von ihren Mitarbeitern, Mitarbeitern und allen Personen, denen sie gegenübersteht, respektiert wird die Informationen zugänglich. </p>
        <h5> <strong> Rechte aus der Verarbeitung personenbezogener Daten </strong> </h5>
        <p> Der Benutzer hat über <span class="blanc-space ng-binding"> Zen-IT </span> und kann daher die folgenden in der RGPD und dem Organgesetz anerkannten Rechte gegen den Datenverantwortlichen ausüben 3/ 2018, vom 5. Dezember, Schutz personenbezogener Daten und Gewährleistung der digitalen Rechte: </p>
        <ul>
            <li>
                <i> Zugriffsrecht: </i> Es ist das Recht des Benutzers, eine Bestätigung darüber zu erhalten, ob <span class="blanc-space ng-binding"> Zen-IT </span> seine persönlichen Daten behandelt oder nicht und wenn ja, Auskunft über Ihre spezifischen personenbezogenen Daten und die Behandlung zu erhalten, die <span class="blanc-space ng-binding"> Zen-IT </span> durchgeführt hat oder durchführt, sowie unter anderem die verfügbaren Informationen über die Herkunft dieser Daten und die Empfänger der diesbezüglichen oder geplanten Mitteilungen.
            </li>
            <li>
                <i> Recht auf Berichtigung: </i> Der Benutzer hat das Recht, seine personenbezogenen Daten ändern zu lassen, die sich als unrichtig oder unter Berücksichtigung der Zwecke der Verarbeitung als unvollständig herausstellen.
            </li>
            <li>
                <i> Recht auf Löschung ("Recht auf Vergessenwerden"): </i> Es ist das Recht des Benutzers, sofern die geltenden Rechtsvorschriften nichts anderes vorschreiben, die Löschung seiner personenbezogenen Daten zu verlangen, wenn er nicht mehr ist für die Zwecke, für die sie erhoben oder verarbeitet wurden, erforderlich sind; Der Benutzer hat seine Zustimmung zur Behandlung widerrufen und dies hat keine andere Rechtsgrundlage; der Benutzer widerspricht der Behandlung und es gibt keinen anderen legitimen Grund, sie fortzusetzen; die personenbezogenen Daten wurden unrechtmäßig verarbeitet; personenbezogene Daten müssen aufgrund einer gesetzlichen Verpflichtung gelöscht werden; oder die personenbezogenen Daten wurden aufgrund eines direkten Angebots von Diensten der Informationsgesellschaft für ein Kind unter 14 Jahren erhoben. Neben der Löschung der Daten muss der Datenverantwortliche unter Berücksichtigung der verfügbaren Technologie und der Kosten ihrer Anwendung angemessene Maßnahmen ergreifen, um die für die Verarbeitung der personenbezogenen Daten Verantwortlichen über den Antrag des Betroffenen auf Löschung aller Links zu diesen personenbezogenen Daten zu informieren .
            </li>
            <li>
                <i> Recht auf Einschränkung der Behandlung: </i> Der Benutzer hat das Recht, die Verarbeitung seiner personenbezogenen Daten einzuschränken. Der Benutzer hat das Recht, die Einschränkung der Behandlung zu verlangen, wenn er die Richtigkeit seiner personenbezogenen Daten bestreitet; die Behandlung ist rechtswidrig; Die für die Behandlung verantwortliche Person benötigt die personenbezogenen Daten nicht mehr, der Benutzer benötigt sie jedoch, um Ansprüche geltend zu machen; und wenn der Benutzer der Behandlung widersprochen hat.
            </li>
            <li>
                <i> Recht auf Datenübertragbarkeit: </i> Für den Fall, dass die Verarbeitung automatisiert erfolgt, hat der Benutzer das Recht, von der für die Verarbeitung Verantwortlichen seine personenbezogenen Daten in einem strukturierten Format zu erhalten, von gemeinsamen Gebrauch und Lesen mechanisch, und an eine andere Person, die für die Behandlung verantwortlich ist, zu übermitteln. Soweit technisch möglich, wird der Verantwortliche die Daten direkt an diesen anderen Verantwortlichen übermitteln.
            </li>
            <li>
                <i> Widerspruchsrecht: </i> Es ist das Recht des Benutzers, die Verarbeitung seiner personenbezogenen Daten nicht durchzuführen oder deren Verarbeitung durch <span class="blanc-space ng-binding"> Zen-IT </span>.
            </li>
            <li>
                <i> Recht, keiner ausschließlich auf einer automatisierten Verarbeitung, einschließlich Profiling, beruhenden Entscheidung unterworfen zu werden: </i> Es besteht das Recht des Nutzers, keiner allein auf einer automatisierten Verarbeitung Ihrer personenbezogenen Daten beruhenden individualisierten Entscheidung unterworfen zu werden, einschließlich Profiling, sofern die geltenden Rechtsvorschriften nichts anderes vorsehen.
            </li>
        </ul>
        <p> Daher kann der Benutzer seine Rechte durch schriftliche Mitteilung an den Datenverantwortlichen mit dem Hinweis "RGPD- <span class=" blanc-space ng-binding "> www.zen-itc.com </span> " und unter folgenden Angaben, ausüben: </p>
        <ul>
            <li> Name, Nachname des Benutzers und Kopie des DNI. In den Fällen, in denen eine Vertretung zugelassen ist, ist auch die Identifizierung der den Nutzer vertretenden Person sowie das Dokument zum Nachweis der Vertretung erforderlich. Die Fotokopie des DNI kann durch jedes andere rechtsgültige Mittel zum Nachweis der Identität ersetzt werden. </li>
            <li>Anfrage mit den spezifischen Gründen für die Anfrage oder Informationen, auf die Sie zugreifen möchten. </li>
            <li> Adresse für Benachrichtigungszwecke. </li>
            <li> Datum und Unterschrift des Antragstellers. </li>
            <li> Jedes Dokument, das die von Ihnen gestellte Anfrage bestätigt. </li>
        </ul>
        <p> Dieser Antrag und alle anderen beigefügten Dokumente können an die folgende Adresse und / oder E-Mail gesendet werden: </p>
        <p>
            Postanschrift: <strong> Avd. dels Paisos Catalans, 28, 2-1, 08380 Malgrat de Mar </strong>
        </p>
        <p>
            E-Mail: <strong> contact@zen-itc.com </strong>
        </p>
        <h5> <strong> Links zu Websites von Drittanbietern </strong> </h5>
        <p> Die Website kann Hyperlinks oder Links enthalten, die den Zugriff auf andere Webseiten Dritter ermöglichen und daher nicht von <span class="blanc-space ng-binding"> Zen-IT </span> betrieben werden. Die Eigentümer dieser Websites haben ihre eigenen Datenschutzrichtlinien und sind jeweils selbst für ihre eigenen Dateien und ihre eigenen Datenschutzpraktiken verantwortlich. </p>
        <h5> <strong> Reklamationen vor der Aufsichtsbehörde </strong> </h5>
        <p> Für den Fall, dass der Benutzer der Ansicht ist, dass bei der Verarbeitung seiner personenbezogenen Daten ein Problem oder ein Verstoß gegen geltende Vorschriften vorliegt, hat er das Recht auf wirksamen Rechtsschutz und auf Klage bei einer Kontrollbehörde , insbesondere in dem Staat, in dem Sie Ihren gewöhnlichen Aufenthalt, Ihren Arbeitsplatz oder den Ort der mutmaßlichen Straftat haben. Im Falle Spaniens ist die Kontrollbehörde die spanische Datenschutzbehörde (http://www.agpd.es). </p>
        <h1 class="container-title-text"> II. Annahme und Änderungen dieser Datenschutzrichtlinie</h1>
        <p> Es ist erforderlich, dass der Benutzer die in dieser Datenschutzerklärung enthaltenen Bedingungen zum Schutz personenbezogener Daten gelesen hat und ihnen zustimmt, sowie dass er die Verarbeitung seiner personenbezogenen Daten akzeptiert, damit der Verantwortliche für die Behandlung fortfahren kann in der Form, während der Fristen und zu den angegebenen Zwecken. Die Nutzung der Website setzt die Annahme ihrer Datenschutzrichtlinie voraus. </p>
        <p>
            <span class="blanc-space ng-binding"> Zen-IT </span> behält sich das Recht vor, seine Datenschutzrichtlinie nach eigenen Kriterien oder aufgrund einer Änderung der Gesetzgebung, Rechtsprechung oder Lehre der spanischen Agentur für . zu ändern Datenschutz. Änderungen oder Aktualisierungen dieser Datenschutzrichtlinie werden dem Benutzer nicht ausdrücklich mitgeteilt. Dem Benutzer wird empfohlen, diese Seite regelmäßig zu konsultieren, um über die neuesten Änderungen oder Aktualisierungen informiert zu sein.
        </p>
        <p> Diese Datenschutzrichtlinie wurde aktualisiert, um der Verordnung (EU) 2016/679 des Europäischen Parlaments und des Rates vom 27. April 2016 zum Schutz natürlicher Personen bei der Verarbeitung personenbezogener Daten und der freien Verbreitung dieser Daten (RGPD) und das Organgesetz 3/2018 vom 5. Dezember über den Schutz personenbezogener Daten und die Gewährleistung digitaler Rechte. </p>
        <p> Dieses Datenschutzrichtliniendokument für eine Website wurde mit dem Generator <a class="text-body text-decoration-none" href="https://textos-legales.edgartamarit.com/" erstellt.> Datenschutzrichtlinie Vorlage </a> am 21.11.2021 online. </p>


    </div>

    <div *ngIf="globService.currentCulture == 'es-ES'" class="container shadow-outside p-5 border-radius-25 bg-default-light mb-75">

        <h1 class="container-title-text">I. POLÍTICA DE PRIVACIDAD Y PROTECCIÓN DE DATOS</h1>
        <p>Respetando lo establecido en la legislación vigente, <span class="blanc-space ng-binding">Zen-IT</span> (en adelante, también Sitio Web) se compromete a adoptar las medidas técnicas y organizativas necesarias, según el nivel de seguridad adecuado al riesgo de los datos recogidos. </p>
        <h5><strong>Leyes que incorpora esta política de privacidad</strong></h5>
        <p>Esta política de privacidad está adaptada a la normativa española y europea vigente en materia de protección de datos personales en internet. En concreto, la misma respeta las siguientes normas:</p>
        <ul>
            <li>El Reglamento (UE) 2016/679 del Parlamento Europeo y del Consejo, de 27 de abril de 2016, relativo a la protección de las personas físicas en lo que respecta al tratamiento de datos personales y a la libre circulación de estos datos (RGPD).</li>
            <li>La Ley Orgánica 3/2018, de 5 de diciembre, de Protección de Datos Personales y garantía de los derechos digitales (LOPD-GDD).</li>
            <li>El Real Decreto 1720/2007, de 21 de diciembre, por el que se aprueba el Reglamento de desarrollo de la Ley Orgánica 15/1999, de 13 de diciembre, de Protección de Datos de Carácter Personal (RDLOPD).</li>
            <li>La Ley 34/2002, de 11 de julio, de Servicios de la Sociedad de la Información y de Comercio Electrónico (LSSI-CE).</li>
        </ul>
        <h5><strong>Identidad del responsable del tratamiento de los datos personales</strong></h5>
        <p ng-show="data.claseTitular == 'persona'">El responsable del tratamiento de los datos personales recogidos en <strong>Zen-IT</strong> es: <strong>Christian Kunze</strong>, con NIF: <strong>X-2972819-T</strong> (en adelante, Responsable del tratamiento). Sus datos de contacto son los siguientes: </p>
        <p>
            Dirección: <strong>Avd. dels Paisos Catalans, 28, 2-1, 08380 Malgrat de Mar</strong>
        </p>
        <p>
            Teléfono de contacto: <strong>+49 177 289 1560</strong>
        </p>
        <p>
            Email de contacto: <strong>contact@zen-itc.com</strong>
        </p>
        <h5><strong>Registro de Datos de Carácter Personal</strong></h5>
        <p>En cumplimiento de lo establecido en el RGPD y la LOPD-GDD, le informamos que los datos personales recabados por <span class="blanc-space ng-binding">Zen-IT</span>, mediante los formularios extendidos en sus páginas quedarán incorporados y serán tratados en nuestro fichero con el fin de poder facilitar, agilizar y cumplir los compromisos establecidos entre <span class="blanc-space ng-binding">Zen-IT</span> y el Usuario o el mantenimiento de la relación que se establezca en los formularios que este rellene, o para atender una solicitud o consulta del mismo. Asimismo, de conformidad con lo previsto en el RGPD y la LOPD-GDD, salvo que sea de aplicación la excepción prevista en el artículo 30.5 del RGPD, se mantine un registro de actividades de tratamiento que especifica, según sus finalidades, las actividades de tratamiento llevadas a cabo y las demás circunstancias establecidas en el RGPD. </p>
        <h5><strong>Principios aplicables al tratamiento de los datos personales</strong></h5>
        <p>El tratamiento de los datos personales del Usuario se someterá a los siguientes principios recogidos en el artículo 5 del RGPD y en el artículo 4 y siguientes de la Ley Orgánica 3/2018, de 5 de diciembre, de Protección de Datos Personales y garantía de los derechos digitales:</p>
        <ul>
            <li>Principio de licitud, lealtad y transparencia: se requerirá en todo momento el consentimiento del Usuario previa información completamente transparente de los fines para los cuales se recogen los datos personales.</li>
            <li>Principio de limitación de la finalidad: los datos personales serán recogidos con fines determinados, explícitos y legítimos.</li>
            <li>Principio de minimización de datos: los datos personales recogidos serán únicamente los estrictamente necesarios en relación con los fines para los que son tratados.</li>
            <li>Principio de exactitud: los datos personales deben ser exactos y estar siempre actualizados.</li>
            <li>Principio de limitación del plazo de conservación: los datos personales solo serán mantenidos de forma que se permita la identificación del Usuario durante el tiempo necesario para los fines de su tratamiento.</li>
            <li>Principio de integridad y confidencialidad: los datos personales serán tratados de manera que se garantice su seguridad y confidencialidad.</li>
            <li>Principio de responsabilidad proactiva: el Responsable del tratamiento será responsable de asegurar que los principios anteriores se cumplen.</li>
        </ul>
        <h5><strong>Categorías de datos personales</strong></h5>
        <p ng-show="data.datosPersonales == 'opcionA'">Las categorías de datos que se tratan en <span class="blanc-space ng-binding">Zen-IT</span> son únicamente datos identificativos. En ningún caso, se tratan categorías especiales de datos personales en el sentido del artículo 9 del RGPD. </p>
        <h5><strong>Base legal para el tratamiento de los datos personales</strong></h5>
        <p>La base legal para el tratamiento de los datos personales es el consentimiento. <span class="blanc-space ng-binding">Zen-IT</span> se compromete a recabar el consentimiento expreso y verificable del Usuario para el tratamiento de sus datos personales para uno o varios fines específicos. </p>
        <p>El Usuario tendrá derecho a retirar su consentimiento en cualquier momento. Será tan fácil retirar el consentimiento como darlo. Como regla general, la retirada del consentimiento no condicionará el uso del Sitio Web.</p>
        <p>En las ocasiones en las que el Usuario deba o pueda facilitar sus datos a través de formularios para realizar consultas, solicitar información o por motivos relacionados con el contenido del Sitio Web, se le informará en caso de que la cumplimentación de alguno de ellos sea obligatoria debido a que los mismos sean imprescindibles para el correcto desarrollo de la operación realizada.</p>
        <h5><strong>Fines del tratamiento a que se destinan los datos personales</strong></h5>
        <p>Los datos personales son recabados y gestionados por <span class="blanc-space ng-binding">Zen-IT</span> con la finalidad de poder facilitar, agilizar y cumplir los compromisos establecidos entre el Sitio Web y el Usuario o el mantenimiento de la relación que se establezca en los formularios que este último rellene o para atender una solicitud o consulta. </p>
        <p>Igualmente, los datos podrán ser utilizados con una finalidad comercial de personalización, operativa y estadística, y actividades propias del objeto social de <span class="blanc-space ng-binding">Zen-IT</span>, así como para la extracción, almacenamiento de datos y estudios de marketing para adecuar el Contenido ofertado al Usuario, así como mejorar la calidad, funcionamiento y navegación por el Sitio Web. </p>
        <p>En el momento en que se obtengan los datos personales, se informará al Usuario acerca del fin o fines específicos del tratamiento a que se destinarán los datos personales; es decir, del uso o usos que se dará a la información recopilada.</p>
        <h5><strong>Períodos de retención de los datos personales</strong></h5>
        <p>Los datos personales solo serán retenidos durante el tiempo mínimo necesario para los fines de su tratamiento y, en todo caso, únicamente durante el siguiente plazo: <span class="blanc-space ng-binding">18 Meses</span>, o hasta que el Usuario solicite su supresión. </p>
        <p>En el momento en que se obtengan los datos personales, se informará al Usuario acerca del plazo durante el cual se conservarán los datos personales o, cuando eso no sea posible, los criterios utilizados para determinar este plazo.</p>
        <h5><strong>Destinatarios de los datos personales</strong></h5>
        <p ng-show="data.datosCompartidos == 'Sí'">Los datos personales del Usuario serán compartidos con los siguientes destinatarios o categorías de destinatarios:</p>
        <ul>
            <li>Google Ireland Limited: Gordon House, Barrow Street, Dublín 4, Irlanda</li>
            <li>STRATO AG: Pascalstraße 10, 10587 Berlin, Alemania</li>
        </ul>
        <p ng-show="data.datosCompartidos == 'Sí'">En caso de que el Responsable del tratamiento tenga la intención de transferir datos personales a un tercer país u organización internacional, en el momento en que se obtengan los datos personales, se informará al Usuario acerca del tercer país u organización internacional al cual se tiene la intención de transferir los datos, así como de la existencia o ausencia de una decisión de adecuación de la Comisión.</p>
        <h5><strong>Datos personales de menores de edad</strong></h5>
        <p>Respetando lo establecido en los artículos 8 del RGPD y 7 de la Ley Orgánica 3/2018, de 5 de diciembre, de Protección de Datos Personales y garantía de los derechos digitales, solo los mayores de 14 años podrán otorgar su consentimiento para el tratamiento de sus datos personales de forma lícita por <span class="blanc-space ng-binding">Zen-IT</span>. Si se trata de un menor de 14 años, será necesario el consentimiento de los padres o tutores para el tratamiento, y este solo se considerará lícito en la medida en la que los mismos lo hayan autorizado. </p>
        <h5><strong>Secreto y seguridad de los datos personales</strong></h5>
        <p>
            <span class="blanc-space ng-binding">Zen-IT</span> se compromete a adoptar las medidas técnicas y organizativas necesarias, según el nivel de seguridad adecuado al riesgo de los datos recogidos, de forma que se garantice la seguridad de los datos de carácter personal y se evite la destrucción, pérdida o alteración accidental o ilícita de datos personales transmitidos, conservados o tratados de otra forma, o la comunicación o acceso no autorizados a dichos datos.
        </p>
        <p ng-show="hasSSL == 'Sí'" class="">El Sitio Web cuenta con un certificado SSL (Secure Socket Layer), que asegura que los datos personales se transmiten de forma segura y confidencial, al ser la transmisión de los datos entre el servidor y el Usuario, y en retroalimentación, totalmente cifrada o encriptada.</p>
        <p>Sin embargo, debido a que <span class="blanc-space ng-binding">Zen-IT</span> no puede garantizar la inexpugabilidad de internet ni la ausencia total de hackers u otros que accedan de modo fraudulento a los datos personales, el Responsable del tratamiento se compromete a comunicar al Usuario sin dilación indebida cuando ocurra una violación de la seguridad de los datos personales que sea probable que entrañe un alto riesgo para los derechos y libertades de las personas físicas. Siguiendo lo establecido en el artículo 4 del RGPD, se entiende por violación de la seguridad de los datos personales toda violación de la seguridad que ocasione la destrucción, pérdida o alteración accidental o ilícita de datos personales transmitidos, conservados o tratados de otra forma, o la comunicación o acceso no autorizados a dichos datos. </p>
        <p>Los datos personales serán tratados como confidenciales por el Responsable del tratamiento, quien se compromete a informar de y a garantizar por medio de una obligación legal o contractual que dicha confidencialidad sea respetada por sus empleados, asociados, y toda persona a la cual le haga accesible la información.</p>
        <h5><strong>Derechos derivados del tratamiento de los datos personales</strong></h5>
        <p>El Usuario tiene sobre <span class="blanc-space ng-binding">Zen-IT</span> y podrá, por tanto, ejercer frente al Responsable del tratamiento los siguientes derechos reconocidos en el RGPD y la Ley Orgánica 3/2018, de 5 de diciembre, de Protección de Datos Personales y garantía de los derechos digitales: </p>
        <ul>
            <li>
                <i>Derecho de acceso:</i> Es el derecho del Usuario a obtener confirmación de si <span class="blanc-space ng-binding">Zen-IT</span> está tratando o no sus datos personales y, en caso afirmativo, obtener información sobre sus datos concretos de carácter personal y del tratamiento que <span class="blanc-space ng-binding">Zen-IT</span> haya realizado o realice, así como, entre otra, de la información disponible sobre el origen de dichos datos y los destinatarios de las comunicaciones realizadas o previstas de los mismos.
            </li>
            <li>
                <i>Derecho de rectificación:</i> Es el derecho del Usuario a que se modifiquen sus datos personales que resulten ser inexactos o, teniendo en cuenta los fines del tratamiento, incompletos.
            </li>
            <li>
                <i>Derecho de supresión ("el derecho al olvido"):</i> Es el derecho del Usuario, siempre que la legislación vigente no establezca lo contrario, a obtener la supresión de sus datos personales cuando estos ya no sean necesarios para los fines para los cuales fueron recogidos o tratados; el Usuario haya retirado su consentimiento al tratamiento y este no cuente con otra base legal; el Usuario se oponga al tratamiento y no exista otro motivo legítimo para continuar con el mismo; los datos personales hayan sido tratados ilícitamentemente; los datos personales deban suprimirse en cumplimiento de una obligación legal; o los datos personales hayan sido obtenidos producto de una oferta directa de servicios de la sociedad de la información a un menor de 14 años. Además de suprimir los datos, el Responsable del tratamiento, teniendo en cuenta la tecnología disponible y el coste de su aplicación, deberá adoptar medidas razonables para informar a los responsables que estén tratando los datos personales de la solicitud del interesado de supresión de cualquier enlace a esos datos personales.
            </li>
            <li>
                <i>Derecho a la limitación del tratamiento:</i> Es el derecho del Usuario a limitar el tratamiento de sus datos personales. El Usuario tiene derecho a obtener la limitación del tratamiento cuando impugne la exactitud de sus datos personales; el tratamiento sea ilícito; el Responsable del tratamiento ya no necesite los datos personales, pero el Usuario lo necesite para hacer reclamaciones; y cuando el Usuario se haya opuesto al tratamiento.
            </li>
            <li>
                <i>Derecho a la portabilidad de los datos:</i> En caso de que el tratamiento se efectúe por medios automatizados, el Usuario tendrá derecho a recibir del Responsable del tratamiento sus datos personales en un formato estructurado, de uso común y lectura mecánica, y a transmitirlos a otro responsable del tratamiento. Siempre que sea técnicamente posible, el Responsable del tratamiento transmitirá directamente los datos a ese otro responsable.
            </li>
            <li>
                <i>Derecho de oposición:</i> Es el derecho del Usuario a que no se lleve a cabo el tratamiento de sus datos de carácter personal o se cese el tratamiento de los mismos por parte de <span class="blanc-space ng-binding">Zen-IT</span>.
            </li>
            <li>
                <i>Derecho a no ser a no ser objeto de una decisión basada únicamente en el tratamiento automatizado, incluida la elaboración de perfiles:</i> Es el derecho del Usuario a no ser objeto de una decisión individualizada basada únicamente en el tratamiento automatizado de sus datos personales, incluida la elaboración de perfiles, existente salvo que la legislación vigente establezca lo contrario.
            </li>
        </ul>
        <p>Así pues, el Usuario podrá ejercitar sus derechos mediante comunicación escrita dirigida al Responsable del tratamiento con la referencia "RGPD- <span class="blanc-space ng-binding">www.zen-itc.com</span>", especificando: </p>
        <ul>
            <li>Nombre, apellidos del Usuario y copia del DNI. En los casos en que se admita la representación, será también necesaria la identificación por el mismo medio de la persona que representa al Usuario, así como el documento acreditativo de la representación. La fotocopia del DNI podrá ser sustituida, por cualquier otro medio válido en derecho que acredite la identidad.</li>
            <li>Petición con los motivos específicos de la solicitud o información a la que se quiere acceder.</li>
            <li>Domicilio a efecto de notificaciones.</li>
            <li>Fecha y firma del solicitante.</li>
            <li>Todo documento que acredite la petición que formula.</li>
        </ul>
        <p>Esta solicitud y todo otro documento adjunto podrá enviarse a la siguiente dirección y/o correo electrónico:</p>
        <p>
            Dirección postal: <strong>Avd. dels Paisos Catalans, 28, 2-1, 08380 Malgrat de Mar</strong>
        </p>
        <p>
            Correo electrónico: <strong>contact@zen-itc.com</strong>
        </p>
        <h5><strong>Enlaces a sitios web de terceros</strong></h5>
        <p>El Sitio Web puede incluir hipervínculos o enlaces que permiten acceder a páginas web de terceros distintos de <span class="blanc-space ng-binding">Zen-IT</span>, y que por tanto no son operados por <span class="blanc-space ng-binding">Zen-IT</span>. Los titulares de dichos sitios web dispondrán de sus propias políticas de protección de datos, siendo ellos mismos, en cada caso, responsables de sus propios ficheros y de sus propias prácticas de privacidad. </p>
        <h5><strong>Reclamaciones ante la autoridad de control</strong></h5>
        <p>En caso de que el Usuario considere que existe un problema o infracción de la normativa vigente en la forma en la que se están tratando sus datos personales, tendrá derecho a la tutela judicial efectiva y a presentar una reclamación ante una autoridad de control, en particular, en el Estado en el que tenga su residencia habitual, lugar de trabajo o lugar de la supuesta infracción. En el caso de España, la autoridad de control es la Agencia Española de Protección de Datos (http://www.agpd.es).</p>
        <h1 class="container-title-text">II. ACEPTACIÓN Y CAMBIOS EN ESTA POLÍTICA DE PRIVACIDAD</h1>
        <p>Es necesario que el Usuario haya leído y esté conforme con las condiciones sobre la protección de datos de carácter personal contenidas en esta Política de Privacidad, así como que acepte el tratamiento de sus datos personales para que el Responsable del tratamiento pueda proceder al mismo en la forma, durante los plazos y para las finalidades indicadas. El uso del Sitio Web implicará la aceptación de la Política de Privacidad del mismo.</p>
        <p>
            <span class="blanc-space ng-binding">Zen-IT</span> se reserva el derecho a modificar su Política de Privacidad, de acuerdo a su propio criterio, o motivado por un cambio legislativo, jurisprudencial o doctrinal de la Agencia Española de Protección de Datos. Los cambios o actualizaciones de esta Política de Privacidad no serán notificados de forma explícita al Usuario. Se recomienda al Usuario consultar esta página de forma periódica para estar al tanto de los últimos cambios o actualizaciones.
        </p>
        <p>Esta Política de Privacidad fue actualizada para adaptarse al Reglamento (UE) 2016/679 del Parlamento Europeo y del Consejo, de 27 de abril de 2016, relativo a la protección de las personas físicas en lo que respecta al tratamiento de datos personales y a la libre circulación de estos datos (RGPD) y a la Ley Orgánica 3/2018, de 5 de diciembre, de Protección de Datos Personales y garantía de los derechos digitales.</p>
        <p>Este documento de Política de Privacidad de un sitio web ha sido creado mediante el generador de <a class="text-body text-decoration-none" href="https://textos-legales.edgartamarit.com/">plantilla de política de privacidad</a> online el día 21/11/2021. </p>


    </div>


    <div *ngIf="globService.currentCulture == 'en-US'" class="container shadow-outside p-5 border-radius-25 bg-default-light mb-75">

        <h1 class="container-title-text"> I. PRIVACY AND DATA PROTECTION POLICY </h1>
        <p> Respecting the provisions of current legislation, <span class="blanc-space ng-binding"> Zen-IT </span> (hereinafter, also Website) undertakes to adopt the necessary technical and organizational measures, according to the level of security appropriate to the risk of the data collected. </p>
        <h5> <strong> Laws included in this privacy policy </strong> </h5>
        <p> This privacy policy is adapted to current Spanish and European regulations regarding the protection of personal data on the internet. Specifically, it respects the following rules: </p>
        <ul>
            <li> Regulation (EU) 2016/679 of the European Parliament and of the Council, of April 27, 2016, regarding the protection of natural persons with regard to the processing of personal data and the free circulation of these data ( GDPR). </li>
            <li> Organic Law 3/2018, of December 5, on the Protection of Personal Data and guarantee of digital rights (LOPD-GDD). </li>
            <li> Royal Decree 1720/2007, of December 21, which approves the Regulations for the development of Organic Law 15/1999, of December 13, on the Protection of Personal Data (RDLOPD). </ li>
            <li> Law 34/2002, of July 11, on Services of the Information Society and Electronic Commerce (LSSI-CE). </li>
        </ul>
        <h5> <strong> Identity of the person responsible for the processing of personal data </strong> </h5>
        <p ng-show="data.claseTitular == 'person'"> The person responsible for the processing of personal data collected in <strong> Zen-IT </strong> is: <strong> Christian Kunze </strong>, with NIF: <strong> X-2972819-T </strong> (hereinafter, Responsible for the treatment). His contact details are as follows: </ p>
        <p>
            Address: <strong> Avd. dels Paisos Catalans, 28, 2-1, 08380 Malgrat de Mar </strong>
        </p>
        <p>
            Contact phone: <strong> +49 177 289 1560 </strong>
        </p>
        <p>
            Contact email: <strong> contact@zen-itc.com </strong>
        </p>
        <h5> <strong> Registration of Personal Data </strong> </h5>
        <p> In compliance with the provisions of the RGPD and the LOPD-GDD, we inform you that the personal data collected by <span class="blanc-space ng-binding"> Zen-IT </span>, through the extended forms in its pages will be incorporated and will be treated in our file in order to facilitate, expedite and fulfill the commitments established between <span class="blanc-space ng-binding"> Zen-IT </span> and the User or the maintenance of the relationship that is established in the forms that this fills out, or to attend a request or query of the same. Likewise, in accordance with the provisions of the RGPD and the LOPD-GDD, unless the exception provided for in article 30.5 of the RGPD is applicable, a record of treatment activities is kept that specifies, according to its purposes, the treatment activities carried out and the other circumstances established in the RGPD. </p>
        <h5> <strong> Principles applicable to the processing of personal data </strong> </h5>
        <p> The processing of the User's personal data will be subject to the following principles set forth in article 5 of the RGPD and in article 4 and following of Organic Law 3/2018, of December 5, on the Protection of Personal Data and guarantee of digital rights: </p>
        <ul>
            <li> Principle of legality, loyalty and transparency: the consent of the User will be required at all times, prior completely transparent information of the purposes for which personal data is collected. </li>
            <li> Principle of purpose limitation: personal data will be collected for specific, explicit and legitimate purposes. </li>
            <li> Principle of data minimization: the personal data collected will only be those strictly necessary in relation to the purposes for which they are processed. </li>
            <li> Principle of accuracy: personal data must be accurate and always up-to-date. </li>
            <li> Principle of limitation of the conservation period: personal data will only be kept in a way that allows the identification of the User for the time necessary for the purposes of its treatment. </li>
            <li> Principle of integrity and confidentiality: personal data will be treated in a way that guarantees its security and confidentiality. </li>
            <li> Principle of proactive responsibility: the Data Controller will be responsible for ensuring that the above principles are met. </li>
        </ul>
        <h5> <strong> Categories of personal data </strong> </h5>
        <p ng-show="data.datosPersonales == 'opcionA'"> The categories of data that are dealt with in <span class="blanc-space ng-binding"> Zen-IT </span> are only identifying data. In no case are special categories of personal data processed in the sense of article 9 of the RGPD. </p>
        <h5> <strong> Legal basis for the processing of personal data </strong> </h5>
        <p> The legal basis for the processing of personal data is consent. <span class="blanc-space ng-binding"> Zen-IT </span> undertakes to obtain the express and verifiable consent of the User for the processing of their personal data for one or more specific purposes. </p>
        <p> The User will have the right to withdraw their consent at any time. It will be as easy to withdraw consent as it is to give it. As a general rule, the withdrawal of consent will not condition the use of the Website. </p>
        <p> On the occasions in which the User must or may provide their data through forms to make inquiries, request information or for reasons related to the content of the Website, they will be informed in the event that the completion of any of They are mandatory because they are essential for the correct development of the operation carried out. </p>
        <h5> <strong> Purposes of the treatment to which the personal data are intended </strong> </h5>
        <p> Personal data is collected and managed by <span class="blanc-space ng-binding"> Zen-IT </span> in order to facilitate, expedite and fulfill the commitments established between the Website and the User or the maintenance of the relationship that is established in the forms that the latter fills in or to attend a request or query. </p>
        <p> Likewise, the data may be used for commercial personalization, operational and statistical purposes, and activities of the corporate purpose of <span class="blanc-space ng-binding"> Zen-IT </span>, as well as well as for the extraction, storage of data and marketing studies to adapt the Content offered to the User, as well as to improve the quality, operation and navigation of the Website. </p>
        <p> At the time the personal data is obtained, the User will be informed about the specific purpose or purposes of the treatment to which the personal data will be used; that is, the use or uses that will be given to the information collected. </p>
        <h5> <strong> Retention periods of personal data </strong> </h5>
        <p> Personal data will only be retained for the minimum time necessary for the purposes of its treatment and, in any case, only during the following period: <span class="blanc-space ng-binding"> 18 month</span> , or until the User requests its deletion. </p>
        <p> At the time the personal data is obtained, the User will be informed about the period during which the personal data will be kept or, when that is not possible, the criteria used to determine this period. </p>
        <h5> <strong> Recipients of personal data </strong> </h5>
        <p ng-show="data.datosCompartidos == 'Yes'"> The User's personal data will be shared with the following recipients or categories of recipients: </p>
        <ul>
            <li> Google Ireland Limited: Gordon House, Barrow Street, Dublin 4, Ireland </li>
            <li> STRATO AG: Pascalstraße 10, 10587 Berlin, Germany </li>
        </ul>
        <p ng-show="data.datosCompartidos == 'Yes'"> In the event that the Data Controller intends to transfer personal data to a third country or international organization, at the time the personal data is obtained , the User will be informed about the third country or international organization to which it is intended to transfer the data, as well as the existence or absence of a Commission adequacy decision. </p>
        <h5> <strong> Personal data of minors </strong> </h5>
        <p> Respecting the provisions of articles 8 of the RGPD and 7 of Organic Law 3/2018, of December 5, on the Protection of Personal Data and guarantee of digital rights, only those over 14 years of age may grant their consent to the lawful processing of your personal data by <span class="blanc-space ng-binding"> Zen-IT </span>. In the case of a minor under 14 years of age, the consent of the parents or guardians will be necessary for the treatment, and this will only be considered lawful to the extent that they have authorized it. </p>
        <h5> <strong> Secrecy and security of personal data </strong> </h5>
        <p>
            <span class="blanc-space ng-binding"> Zen-IT </span> undertakes to adopt the necessary technical and organizational measures, according to the level of security appropriate to the risk of the data collected, so as to guarantee the personal data security and avoid accidental or illegal destruction, loss or alteration of personal data transmitted, stored or otherwise processed, or unauthorized communication or access to said data.
        </p>
        <p ng-show="hasSSL == 'Yes'" class=""> The Website has an SSL (Secure Socket Layer) certificate, which ensures that personal data is transmitted in a secure and confidential way, as it is the transmission of data between the server and the User, and in feedback, fully encrypted or encrypted. </p>
        <p> However, since <span class="blanc-space ng-binding"> Zen-IT </span> cannot guarantee the unenforceability of the internet or the total absence of hackers or others who fraudulently access personal data, the Data Controller undertakes to notify the User without undue delay when there is a violation of the security of personal data that is likely to entail a high risk to the rights and freedoms of natural persons. Following the provisions of article 4 of the RGPD, a violation of the security of personal data is understood to be any violation of security that causes the destruction, loss or accidental or illegal alteration of personal data transmitted, stored or otherwise processed, or unauthorized communication or access to said data. </p>
        <p> Personal data will be treated as confidential by the person in charge of the treatment, who undertakes to inform about and guarantee through a legal or contractual obligation that said confidentiality is respected by his employees, associates, and any person to whom he make the information accessible. </p>
        <h5> <strong> Rights derived from the processing of personal data </strong> </h5>
        <p> The User has over <span class="blanc-space ng-binding"> Zen-IT </span> and may, therefore, exercise the following rights recognized in the RGPD and the Organic Law against the Data Controller 3/2018, of December 5, Protection of Personal Data and guarantee of digital rights: </p>
        <ul>
            <li>
                <i> Right of access: </i> It is the right of the User to obtain confirmation of whether <span class="blanc-space ng-binding"> Zen-IT </span> is treating their personal data or not and, if so, obtain information about your specific personal data and the treatment that <span class="blanc-space ng-binding"> Zen-IT </span> has carried out or carries out, as well as, among others, the Information available on the origin of said data and the recipients of the communications made or planned thereof.
            </li>
            <li>
                <i> Right of rectification: </i> It is the right of the User to have their personal data modified that turns out to be inaccurate or, taking into account the purposes of the treatment, incomplete.
            </li>
            <li>
                <i> Right of deletion ("the right to be forgotten"): </i> It is the right of the User, provided that current legislation does not establish otherwise, to obtain the deletion of their personal data when they are no longer necessary for the purposes for which they were collected or processed; The User has withdrawn his consent to the treatment and this does not have another legal basis; the User opposes the treatment and there is no other legitimate reason to continue with it; the personal data has been unlawfully processed; personal data must be deleted in compliance with a legal obligation; or the personal data has been obtained as a result of a direct offer of information society services to a child under 14 years of age. In addition to deleting the data, the Data Controller, taking into account the available technology and the cost of its application, must adopt reasonable measures to inform those responsible for processing the personal data of the interested party's request to delete any link to those personal data.
            </li>
            <li>
                <i> Right to limitation of treatment: </i> It is the right of the User to limit the processing of their personal data. The User has the right to obtain the limitation of the treatment when he disputes the accuracy of his personal data; the treatment is unlawful; The person in charge of the treatment no longer needs the personal data, but the User needs it to make claims; and when the User has opposed the treatment.
            </li>
            <li>
                <i> Right to data portability: </i> In the event that the treatment is carried out by automated means, the User will have the right to receive from the Person in charge of the treatment their personal data in a structured format, of common use and reading mechanical, and to transmit them to another person in charge of the treatment. Whenever technically possible, the Data Controller will directly transmit the data to that other controller.
            </li>
            <li>
                <i> Right of opposition: </i> It is the User's right not to carry out the processing of their personal data or to cease their processing by <span class="blanc-space ng-binding "> Zen-IT </span>.
            </li>
            <li>
                <i> Right not to be the subject of a decision based solely on automated processing, including profiling: </i> It is the right of the User not to be subject to an individualized decision based solely on automated processing of your personal data, including profiling, existing unless current legislation establishes otherwise.
            </li>
        </ul>
        <p> Thus, the User may exercise their rights by written communication addressed to the Data Controller with the reference "RGPD- <span class=" blanc-space ng-binding "> www.zen-itc.com </span> ", specifying: </p>
        <ul>
            <li> Name, surname of the User and copy of the DNI. In the cases in which representation is admitted, it will also be necessary to identify the person representing the User by the same means, as well as the document proving the representation. The photocopy of the DNI may be replaced by any other legally valid means that proves identity. </li>
            <li> Request with the specific reasons for the request or information to which you want to access. </li>
            <li> Address for notification purposes. </li>
            <li> Date and signature of the applicant. </li>
            <li> Any document that accredits the request you make. </li>
        </ul>
        <p> This application and any other attached document may be sent to the following address and / or email: </p>
        <p>
            Postal address: <strong> Avd. dels Paisos Catalans, 28, 2-1, 08380 Malgrat de Mar </strong>
        </p>
        <p>
            Email: <strong> contact@zen-itc.com </strong>
        </p>
        <h5> <strong> Links to third party websites </strong> </h5>
        <p> The Website may include hyperlinks or links that allow access to third-party web pages other than <span class="blanc-space ng-binding"> Zen-IT </span>, and that therefore are not operated by <span class="blanc-space ng-binding"> Zen-IT </span>. The owners of these websites will have their own data protection policies, being themselves, in each case, responsible for their own files and their own privacy practices. </p>
        <h5> <strong> Claims before the supervisory authority </strong> </h5>
        <p> In the event that the User considers that there is a problem or violation of current regulations in the way in which their personal data is being processed, they will have the right to effective judicial protection and to file a claim with a control authority, in particular, in the State in which you have your habitual residence, place of work or place of the alleged offense. In the case of Spain, the control authority is the Spanish Data Protection Agency (http://www.agpd.es). </p>
        <h1 class="container-title-text"> II. ACCEPTANCE AND CHANGES TO THIS PRIVACY POLICY </h1>
        <p> It is necessary that the User has read and agrees with the conditions on the protection of personal data contained in this Privacy Policy, as well as that they accept the processing of their personal data so that the Responsible for the treatment can proceed to same in the form, during the deadlines and for the purposes indicated. The use of the Website will imply the acceptance of its Privacy Policy. </p>
        <p>
            <span class="blanc-space ng-binding"> Zen-IT </span> reserves the right to modify its Privacy Policy, according to its own criteria, or motivated by a legislative, jurisprudential or doctrinal change of the Spanish Agency for Data Protection. Changes or updates to this Privacy Policy will not be explicitly notified to the User. The User is recommended to consult this page periodically to be aware of the latest changes or updates.
        </p>
        <p> This Privacy Policy was updated to adapt to Regulation (EU) 2016/679 of the European Parliament and of the Council, of April 27, 2016, regarding the protection of natural persons with regard to the processing of personal data and the free circulation of these data (RGPD) and Organic Law 3/2018, of December 5, on the Protection of Personal Data and guarantee of digital rights. </p>
        <p> This Privacy Policy document for a website has been created using the <a class="text-body text-decoration-none" href="https://textos-legales.edgartamarit.com/" generator.> privacy policy template </a> online on 11/21/2021. </p>



    </div>

</section>