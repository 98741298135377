


<div class="PageContent bg-default-light parallax">
    <!-- Main page always has 2 components: domain + globalization: -->
    <main-component class="preserve-3D display-block" *ngIf="router.url.split('/').length == 2 "></main-component>

    <legal-notice-component class="preserve-3D display-block"  *ngIf="router.url.includes('legal-notice')"></legal-notice-component>
    <privacy-component class="preserve-3D display-block"  *ngIf="router.url.includes('privacy')"></privacy-component>
    <cookies-component class="preserve-3D display-block"  *ngIf="router.url.includes('cookies')"></cookies-component>
    <services-component class="preserve-3D display-block"  *ngIf="router.url.includes('services')"></services-component>

    <contact-component class="preserve-3D display-block"></contact-component>
    <footer-component class="preserve-3D display-block" ></footer-component>
</div>