import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { GlobalizationService } from '../../shared/globalization/globalizationService';

@Component({
    selector: "it-consulting-service-component",
    templateUrl: "./it-consulting-service.component.html",
    styleUrls: ["../services.component.css", "./it-consulting-service.component.vars.css", "./it-consulting-service.component.css"]
})
export class ITConsultingService {

    constructor(private router: Router, public globService: GlobalizationService) {

    }
}