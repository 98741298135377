import { Component, ElementRef } from '@angular/core';
import { Router } from '@angular/router';

@Component({
  selector: 'app-root', templateUrl: "./app.component.html", styles: ["./app.component.css"]
})
export class AppComponent {
    title = 'Zen-IT';

    public url: string = "";

    //public global_strings: string = "";
    //public local_strings: string = "";

    constructor(public router: Router, private elementRef: ElementRef ) {

    }
}



