<section class="header h-250 min-h-250">
</section>


<section id="header-section" class="section p-4">

    <div *ngIf="globService.currentCulture == 'de-DE'" class="container shadow-outside p-5 border-radius-25 bg-default-light mb-75">


        <h1 class="container-title-text"> I. Allgemeine Informationen </h1>
        <p> In Übereinstimmung mit der Informationspflicht des Gesetzes 34/2002 über Dienstleistungen der Informationsgesellschaft und des elektronischen Geschäftsverkehrs (LSSI-CE) vom 11. Juli die folgenden allgemeinen Informationsdaten auf dieser Website: </p>
        <p ng-show="data.claseTitular == 'person'"> Das Eigentum an dieser Website, <strong> www.zen-itc.com </strong>, (im Folgenden Website) wird gehalten von: <strong> Christian Kunze </strong>, mit NIF: <strong> X-2972819-T </strong>, und dessen Kontaktdaten sind: </p>
        <p>
            Adresse: <strong> Avd. dels Paisos Catalans 28, 2-1, 08380, Malgrat de Mar </strong>
        </p>
        <p>
            Kontakttelefon: <strong> +49 177 289 1560 </strong>
        </p>
        <p>
            Kontakt-E-Mail: <strong> contact@zen-itc.com </strong>
        </p>
        <h1 class="container-title-text"> II. Allgemeine Nutzungsbedingungen </h1>
        <h5> <strong> Der Zweck der Bedingungen: Die Website </strong> </h5>
        <p> Der Zweck dieser Allgemeinen Nutzungsbedingungen (im Folgenden: Bedingungen) besteht darin, den Zugang und die Nutzung der Website zu regeln. Im Sinne dieser Bedingungen wird die Website verstanden als: das äußere Erscheinungsbild der Bildschirmschnittstellen, sowohl statisch als auch dynamisch, dh der Navigationsbaum; und alle Elemente, die sowohl in die Bildschirmoberflächen als auch in den Navigationsbaum integriert sind (im Folgenden Inhalte genannt) und alle Dienste oder Online-Ressourcen, die den Benutzern angeboten werden können (im Folgenden Dienste). </p>
        <p>
            <span> Zen-IT </span> behält sich das Recht vor, die Darstellung und Konfiguration der Website und der darin enthaltenen Inhalte und Dienste jederzeit und ohne vorherige Ankündigung zu ändern. Der Benutzer erkennt an und akzeptiert, dass <span> Zen-IT </span> jederzeit jedes dieser Elemente, die in die Website integriert sind oder auf sie zugreifen, unterbrechen, deaktivieren und / oder löschen kann.
        </p>
        <p ng-show="data.accesoLibre == 'Sí'"> Der Zugriff auf die Website durch den Benutzer ist kostenlos und in der Regel kostenlos, ohne dass der Benutzer eine Gegenleistung erbringen muss, um sie nutzen zu können. , mit Ausnahme der Kosten für die Verbindung über das Telekommunikationsnetz des vom Benutzer beauftragten Zugangsanbieters. </p>
        <p ng-show="data.registroLibre == 'Yes'" class=""> Die Nutzung von Inhalten oder Diensten der Website kann durch ein Abonnement oder eine vorherige Registrierung des Benutzers erfolgen. </p>
        <h5> <strong> Der Benutzer </strong> </h5>
        <p> Der Zugang, die Navigation und die Nutzung der Website verleiht dem Benutzer die Bedingung, dass er ab dem Beginn des Surfens auf der Website alle hier festgelegten Bedingungen sowie deren spätere Änderungen ohne Beeinträchtigung der Anwendung der entsprechenden zwingenden gesetzlichen Regelungen im Einzelfall. Angesichts der Relevanz des Vorstehenden wird dem Benutzer empfohlen, diese bei jedem Besuch der Website zu lesen. </p>
        <p> Die <span> Zen-IT </span> Website bietet eine Vielzahl von Informationen, Diensten und Daten. Der Benutzer übernimmt seine Verantwortung für eine korrekte Nutzung der Website. Diese Verantwortung erstreckt sich auf: </p>
        <ul>
            <li> Eine Nutzung der Informationen, Inhalte und/oder Dienste und Daten, die von <span> Zen-IT </span> angeboten werden, ohne gegen die Bestimmungen dieser Bedingungen, das Gesetz, die guten Sitten oder die öffentliche Ordnung oder in irgendeiner Weise zu verstoßen Auf andere Weise können die Rechte Dritter oder der Betrieb der Website verletzt werden. </li>
            <li> Die Richtigkeit und Rechtmäßigkeit der vom Benutzer in den von <span> Zen-IT </span> ausgegebenen Formularen für den Zugriff auf bestimmte Inhalte oder Dienste der Website bereitgestellten Informationen. In jedem Fall wird der Benutzer <span> Zen-IT </span> unverzüglich über jede Tatsache informieren, die den Missbrauch der in diesen Formularen registrierten Informationen ermöglicht, wie z. B. Diebstahl, Verlust oder unbefugter Zugriff auf Kennungen und / oder Passwörter, um mit ihrer sofortigen Löschung fortzufahren. </li>
        </ul>
        <p> Der bloße Zugriff auf diese Website impliziert keine kommerzielle Beziehung zwischen <span> Zen-IT </span> und dem Benutzer. </p>
        <p ng-show="data.mayorEdad == 'No'" class=""> Diese <span> Zen-IT </span> Website richtet sich immer in Übereinstimmung mit den geltenden Gesetzen an alle Menschen, unabhängig von ihrem Alter , dass sie auf die Seiten der Website zugreifen und / oder darin navigieren können. </p>
        <h1 class="container-title-text"> III. Zugriff und Navigation auf der Website: Ausschluss von Garantien und Verantwortung </h1>
        <p>
            <span> Zen-IT </span> garantiert weder die Kontinuität, Verfügbarkeit und Nützlichkeit der Website noch der Inhalte oder Dienste. <span> Zen-IT </span> wird alles tun, um das ordnungsgemäße Funktionieren der Website zu gewährleisten, ist jedoch nicht dafür verantwortlich oder garantiert, dass der Zugriff auf diese Website nicht ununterbrochen oder fehlerfrei ist.
        </p>
        <p> Sie ist weder verantwortlich noch garantiert sie, dass die Inhalte oder Software, auf die über diese Website zugegriffen werden kann, fehlerfrei sind oder das Computersystem des Benutzers (Software und Hardware) beschädigen. In keinem Fall ist <span> Zen-IT </span> für Verluste, Schäden oder Schäden jeglicher Art verantwortlich, die sich aus dem Zugriff, der Navigation und der Nutzung der Website ergeben, einschließlich, aber nicht beschränkt auf die Computersysteme oder die durch die Einschleppung von Viren verursacht werden. </p>
        <p>
            <span> Zen-IT </span> ist nicht verantwortlich für Schäden, die Benutzern durch unsachgemäße Nutzung dieser Website entstehen können. Insbesondere haftet sie in keiner Weise für eventuell auftretende Stürze, Unterbrechungen, Ausfälle oder Mängel der Telekommunikation.
        </p>
        <h1 class="container-title-text"> IV. Link-Richtlinie </h1>
        <p ng-show="data.enlacesTerceros == 'Yes'"> Es wird berichtet, dass die <span> Zen-IT </span> Website Linkmittel (wie z. B. zwischen anderen Links, Banner, Schaltflächen), Verzeichnisse und Suchmaschinen, die es Benutzern ermöglichen, auf Websites zuzugreifen, die sich im Besitz von Dritten befinden und / oder von diesen verwaltet werden. </p>
        <p ng-show="data.enlacesTerceros == 'Yes'"> Die Installation dieser Links, Verzeichnisse und Suchmaschinen auf der Website soll den Benutzern die Suche und den Zugriff auf im Internet verfügbare Informationen erleichtern, ohne dass dies als Vorschlag, Empfehlung oder Einladung zum Besuch angesehen. </p>
        <p ng-show="data.Drittanbieterlinks == 'Ja' & amp; & amp; data.enlacesAffiliation == 'Nein'">
            <span> Zen-IT </span> bietet oder vermarktet die auf den verlinkten Seiten verfügbaren Produkte und/oder Dienstleistungen weder selbst noch durch Dritte.
        </p>
        <p ng-show="data.enlacesTerceros == 'Yes'"> Ebenso wird keine Garantie für die technische Verfügbarkeit, Genauigkeit, Richtigkeit, Gültigkeit oder Rechtmäßigkeit von Websites außerhalb ihres Eigentums übernommen, auf die über die Links zugegriffen werden kann. </p>
        <p ng-show="data.enlacesTerceros == 'Ja'">
            <span> Zen-IT </span> wird in keinem Fall den Inhalt anderer Websites überprüfen oder kontrollieren, noch wird es die Produkte und Dienstleistungen, Inhalte, Dateien und anderes Material auf den oben genannten verlinkten Websites genehmigen, untersuchen oder befürworten.
        </p>
        <p ng-show="data.enlacesTerceros == 'Ja'">
            <span> Zen-IT </span> übernimmt keine Verantwortung für Schäden, die aufgrund des Zugriffs, der Nutzung, der Qualität oder der Rechtmäßigkeit der Inhalte, Mitteilungen, Meinungen, Produkte und Dienstleistungen der nicht von <span> verwalteten Websites entstehen können Zen-IT </span> und dass sie auf dieser Website verlinkt sind.
        </p>
        <p> Der Benutzer oder Dritte, der einen Hyperlink von einer Webseite einer anderen, anderen Website zur <span> Zen-IT </span> Website erstellt, muss Folgendes wissen: </p>
        <p> Die Vervielfältigung – ganz oder teilweise – von Inhalten und/oder Diensten der Website ist ohne die ausdrückliche Genehmigung von <span> Zen-IT </span> nicht gestattet. </p>
        <p> Auf der <span> Zen-IT </span>-Website sowie deren Inhalten und/oder Diensten ist keine falsche, ungenaue oder falsche Darstellung erlaubt. </p>
        <p> Mit Ausnahme des Hyperlinks enthält die Website, auf der dieser Hyperlink eingerichtet ist, kein Element dieser Website, das durch das spanische Rechtssystem als geistiges Eigentum geschützt ist, es sei denn, dies wurde ausdrücklich von <span> Zen-IT </span> genehmigt. </p>
        <p> Die Einrichtung des Hyperlinks impliziert weder die Existenz von Beziehungen zwischen <span> Zen-IT </span> und dem Eigentümer der Website, von der aus er erstellt wurde, noch die Kenntnis und Annahme von <span> Zen-IT </span> der auf dieser Website angebotenen Inhalte, Dienste und / oder Aktivitäten und umgekehrt. </p>
        <h1 class="container-title-text"> V. Geistiges und gewerbliches Eigentum </h1>
        <p>
            <span> Zen-IT </span> ist allein oder als Übertragungsempfänger Eigentümer aller geistigen und gewerblichen Eigentumsrechte der Website sowie der darin enthaltenen Elemente (beispielhaft und nicht erschöpfend, Bilder, Ton, Audio, Video, Software oder Texte, Marken oder Logos, Farbkombinationen, Struktur und Design, Auswahl der verwendeten Materialien, für deren Betrieb notwendige Computerprogramme, Zugriff und Nutzung usw.). Es handelt sich daher um Werke, die durch das spanische Rechtssystem als geistiges Eigentum geschützt sind, wobei sowohl die spanischen und gemeinschaftlichen Vorschriften in diesem Bereich als auch die einschlägigen internationalen Verträge gelten, die von Spanien unterzeichnet wurden.
        </p>
        <p> Alle Rechte vorbehalten. Gemäß den Bestimmungen des Gesetzes über geistiges Eigentum ist die Vervielfältigung, Verbreitung und öffentliche Wiedergabe einschließlich der Art der Zugänglichmachung des gesamten oder eines Teils des Inhalts dieser Webseite zu kommerziellen Zwecken ausdrücklich untersagt und mit jeglichen technischen Mitteln, ohne die Genehmigung von <span> Zen-IT </span>. </p>
        <p> Der Benutzer verpflichtet sich, die geistigen und gewerblichen Eigentumsrechte von <span> Zen-IT </span> zu respektieren. Sie können die Elemente der Website anzeigen oder sogar ausdrucken, kopieren und auf der Festplatte Ihres Computers oder auf einem anderen physischen Medium speichern, solange es ausschließlich für Ihren persönlichen Gebrauch bestimmt ist. Der Benutzer darf jedoch keine auf der Website installierten Schutzvorrichtungen oder Sicherheitssysteme löschen, ändern oder manipulieren. </p>
        <p> Für den Fall, dass der Benutzer oder Dritte der Ansicht sind, dass der Inhalt der Website eine Verletzung der Rechte zum Schutz des geistigen Eigentums darstellt, müssen sie <span> Zen-IT </span> unverzüglich über den Kontakt . benachrichtigen Einzelheiten zum Abschnitt ALLGEMEINE INFORMATIONEN dieses rechtlichen Hinweises und der Allgemeinen Nutzungsbedingungen. </p>
        <h1 class="container-title-text"> VI. Klagen, anwendbare Rechtsvorschriften und Gerichtsstand </h1>
        <p>
            <span> Zen-IT </span> behält sich das Recht vor, zivil- oder strafrechtliche Schritte einzuleiten, die es für die missbräuchliche Nutzung der Website und der Inhalte oder für den Verstoß gegen diese Bedingungen für erforderlich hält.
        </p>
        <p> Die Beziehung zwischen dem Benutzer und <span> Zen-IT </span> unterliegt den geltenden Vorschriften und gilt auf spanischem Gebiet. Sollten Streitigkeiten in Bezug auf die Auslegung und / oder Anwendung dieser Bedingungen auftreten, unterbreiten die Parteien ihre Konflikte der ordentlichen Gerichtsbarkeit und unterbreiten sie den entsprechenden Richtern und Gerichten in Übereinstimmung mit dem Gesetz. </p>
        <p> Dieses Dokument mit den rechtlichen Hinweisen und den Allgemeinen Nutzungsbedingungen der Website wurde mit dem Generator von <a class="text-body text-decoration-none" href="https://textos-legales.edgartamarit. com / "> Vorlage für rechtliche Hinweise und Nutzungsbedingungen </a> online am 21.11.2021. </p>



    </div>

    <div *ngIf="globService.currentCulture == 'es-ES'" class="container shadow-outside p-5 border-radius-25 bg-default-light mb-75">


        <h1 class="container-title-text">I. Información General</h1>
        <p>En cumplimiento con el deber de información dispuesto en la Ley 34/2002 de Servicios de la Sociedad de la Información y el Comercio Electrónico (LSSI-CE) de 11 de julio, se facilitan a continuación los siguientes datos de información general de este sitio web:</p>
        <p ng-show="data.claseTitular == 'persona'">La titularidad de este sitio web, <strong>www.zen-itc.com</strong>, (en adelante, Sitio Web) la ostenta: <strong>Christian Kunze</strong>, con NIF: <strong>X-2972819-T</strong>, y cuyos datos de contacto son: </p>
         <p>
            Dirección: <strong>Avd. dels Paisos Catalans 28, 2-1, 08380, Malgrat de Mar</strong>
        </p>
        <p>
            Teléfono de contacto: <strong>+49 177 289 1560</strong>
        </p>
        <p>
            Email de contacto: <strong>contact@zen-itc.com</strong>
        </p>
        <h1 class="container-title-text">II. Términos y Condiciones Generales de Uso</h1>
        <h5><strong>El objeto de las condiciones: El Sitio Web</strong></h5>
        <p>El objeto de las presentes Condiciones Generales de Uso (en adelante, Condiciones) es regular el acceso y la utilización del Sitio Web. A los efectos de las presentes Condiciones se entenderá como Sitio Web: la apariencia externa de los interfaces de pantalla, tanto de forma estática como de forma dinámica, es decir, el árbol de navegación; y todos los elementos integrados tanto en los interfaces de pantalla como en el árbol de navegación (en adelante, Contenidos) y todos aquellos servicios o recursos en línea que en su caso ofrezca a los Usuarios (en adelante, Servicios).</p>
        <p>
            <span >Zen-IT</span> se reserva la facultad de modificar, en cualquier momento, y sin aviso previo, la presentación y configuración del Sitio Web y de los Contenidos y Servicios que en él pudieran estar incorporados. El Usuario reconoce y acepta que en cualquier momento <span >Zen-IT</span> pueda interrumpir, desactivar y/o cancelar cualquiera de estos elementos que se integran en el Sitio Web o el acceso a los mismos.
        </p>
        <p ng-show="data.accesoLibre == 'Sí'">El acceso al Sitio Web por el Usuario tiene carácter libre y, por regla general, es gratuito sin que el Usuario tenga que proporcionar una contraprestación para poder disfrutar de ello, salvo en lo relativo al coste de conexión a través de la red de telecomunicaciones suministrada por el proveedor de acceso que hubiere contratado el Usuario.</p>
        <p ng-show="data.registroLibre == 'Sí'" class="">La utilización de alguno de los Contenidos o Servicios del Sitio Web podrá hacerse mediante la suscripción o registro previo del Usuario.</p>
        <h5><strong>El Usuario</strong></h5>
        <p>El acceso, la navegación y uso del Sitio Web, confiere la condición de Usuario, por lo que se aceptan, desde que se inicia la navegación por el Sitio Web, todas las Condiciones aquí establecidas, así como sus ulteriores modificaciones, sin perjuicio de la aplicación de la correspondiente normativa legal de obligado cumplimiento según el caso. Dada la relevancia de lo anterior, se recomienda al Usuario leerlas cada vez que visite el Sitio Web. </p>
        <p>El Sitio Web de <span >Zen-IT</span> proporciona gran diversidad de información, servicios y datos. El Usuario asume su responsabilidad para realizar un uso correcto del Sitio Web. Esta responsabilidad se extenderá a: </p>
        <ul>
            <li>Un uso de la información, Contenidos y/o Servicios y datos ofrecidos por <span >Zen-IT</span> sin que sea contrario a lo dispuesto por las presentes Condiciones, la Ley, la moral o el orden público, o que de cualquier otro modo puedan suponer lesión de los derechos de terceros o del mismo funcionamiento del Sitio Web. </li>
            <li>La veracidad y licitud de las informaciones aportadas por el Usuario en los formularios extendidos por <span >Zen-IT</span> para el acceso a ciertos Contenidos o Servicios ofrecidos por el Sitio Web. En todo caso, el Usuario notificará de forma inmediata a <span >Zen-IT</span> acerca de cualquier hecho que permita el uso indebido de la información registrada en dichos formularios, tales como, pero no sólo, el robo, extravío, o el acceso no autorizado a identificadores y/o contraseñas, con el fin de proceder a su inmediata cancelación. </li>
        </ul>
        <p>El mero acceso a este Sitio Web no supone entablar ningún tipo de relación de carácter comercial entre <span >Zen-IT</span> y el Usuario. </p>
        <p ng-show="data.mayorEdad == 'No'" class="">Siempre en el respeto de la legislación vigente, este Sitio Web de <span >Zen-IT</span> se dirige a todas las personas, sin importar su edad, que puedan acceder y/o navegar por las páginas del Sitio Web. </p>
        <h1 class="container-title-text">III. Acceso y Navegación en el Sitio Web: Exclusión de Garantías y Responsabilidad</h1>
        <p>
            <span >Zen-IT</span> no garantiza la continuidad, disponibilidad y utilidad del Sitio Web, ni de los Contenidos o Servicios. <span >Zen-IT</span> hará todo lo posible por el buen funcionamiento del Sitio Web, sin embargo, no se responsabiliza ni garantiza que el acceso a este Sitio Web no vaya a ser ininterrumpido o que esté libre de error.
        </p>
        <p>Tampoco se responsabiliza o garantiza que el contenido o software al que pueda accederse a través de este Sitio Web, esté libre de error o cause un daño al sistema informático (software y hardware) del Usuario. En ningún caso <span >Zen-IT</span> será responsable por las pérdidas, daños o perjuicios de cualquier tipo que surjan por el acceso, navegación y el uso del Sitio Web, incluyéndose, pero no limitándose, a los ocasionados a los sistemas informáticos o los provocados por la introducción de virus. </p>
        <p>
            <span >Zen-IT</span> tampoco se hace responsable de los daños que pudiesen ocasionarse a los usuarios por un uso inadecuado de este Sitio Web. En particular, no se hace responsable en modo alguno de las caídas, interrupciones, falta o defecto de las telecomunicaciones que pudieran ocurrir.
        </p>
        <h1 class="container-title-text">IV. Política de Enlaces</h1>
        <p ng-show="data.enlacesTerceros == 'Sí'">Se informa que el Sitio Web de <span >Zen-IT</span> pone o puede poner a disposición de los Usuarios medios de enlace (como, entre otros, links, banners, botones), directorios y motores de búsqueda que permiten a los Usuarios acceder a sitios web pertenecientes y/o gestionados por terceros. </p>
        <p ng-show="data.enlacesTerceros == 'Sí'">La instalación de estos enlaces, directorios y motores de búsqueda en el Sitio Web tiene por objeto facilitar a los Usuarios la búsqueda de y acceso a la información disponible en Internet, sin que pueda considerarse una sugerencia, recomendación o invitación para la visita de los mismos.</p>
        <p ng-show="data.enlacesTerceros == 'Sí' &amp;&amp; data.enlacesAfiliacion == 'No'">
            <span >Zen-IT</span> no ofrece ni comercializa por sí ni por medio de terceros los productos y/o servicios disponibles en dichos sitios enlazados.
        </p>
        <p ng-show="data.enlacesTerceros == 'Sí'">Asimismo, tampoco garantizará la disponibilidad técnica, exactitud, veracidad, validez o legalidad de sitios ajenos a su propiedad a los que se pueda acceder por medio de los enlaces.</p>
        <p ng-show="data.enlacesTerceros == 'Sí'">
            <span >Zen-IT</span> en ningún caso revisará o controlará el contenido de otros sitios web, así como tampoco aprueba, examina ni hace propios los productos y servicios, contenidos, archivos y cualquier otro material existente en los referidos sitios enlazados.
        </p>
        <p ng-show="data.enlacesTerceros == 'Sí'">
            <span >Zen-IT</span> no asume ninguna responsabilidad por los daños y perjuicios que pudieran producirse por el acceso, uso, calidad o licitud de los contenidos, comunicaciones, opiniones, productos y servicios de los sitios web no gestionados por <span >Zen-IT</span> y que sean enlazados en este Sitio Web.
        </p>
        <p>El Usuario o tercero que realice un hipervínculo desde una página web de otro, distinto, sitio web al Sitio Web de <span >Zen-IT</span> deberá saber que: </p>
        <p>No se permite la reproducción —total o parcialmente— de ninguno de los Contenidos y/o Servicios del Sitio Web sin autorización expresa de <span >Zen-IT</span>. </p>
        <p>No se permite tampoco ninguna manifestación falsa, inexacta o incorrecta sobre el Sitio Web de <span >Zen-IT</span>, ni sobre los Contenidos y/o Servicios del mismo. </p>
        <p>A excepción del hipervínculo, el sitio web en el que se establezca dicho hiperenlace no contendrá ningún elemento, de este Sitio Web, protegido como propiedad intelectual por el ordenamiento jurídico español, salvo autorización expresa de <span >Zen-IT</span>. </p>
        <p>El establecimiento del hipervínculo no implicará la existencia de relaciones entre <span >Zen-IT</span> y el titular del sitio web desde el cual se realice, ni el conocimiento y aceptación de <span >Zen-IT</span> de los contenidos, servicios y/o actividades ofrecidos en dicho sitio web, y viceversa. </p>
        <h1 class="container-title-text">V. Propiedad Intelectual e Industrial</h1>
        <p>
            <span >Zen-IT</span> por sí o como parte cesionaria, es titular de todos los derechos de propiedad intelectual e industrial del Sitio Web, así como de los elementos contenidos en el mismo (a título enunciativo y no exhaustivo, imágenes, sonido, audio, vídeo, software o textos, marcas o logotipos, combinaciones de colores, estructura y diseño, selección de materiales usados, programas de ordenador necesarios para su funcionamiento, acceso y uso, etc.). Serán, por consiguiente, obras protegidas como propiedad intelectual por el ordenamiento jurídico español, siéndoles aplicables tanto la normativa española y comunitaria en este campo, como los tratados internacionales relativos a la materia y suscritos por España.
        </p>
        <p>Todos los derechos reservados. En virtud de lo dispuesto en la Ley de Propiedad Intelectual, quedan expresamente prohibidas la reproducción, la distribución y la comunicación pública, incluida su modalidad de puesta a disposición, de la totalidad o parte de los contenidos de esta página web, con fines comerciales, en cualquier soporte y por cualquier medio técnico, sin la autorización de <span >Zen-IT</span>. </p>
        <p>El Usuario se compromete a respetar los derechos de propiedad intelectual e industrial de <span >Zen-IT</span>. Podrá visualizar los elementos del Sitio Web o incluso imprimirlos, copiarlos y almacenarlos en el disco duro de su ordenador o en cualquier otro soporte físico siempre y cuando sea, exclusivamente, para su uso personal. El Usuario, sin embargo, no podrá suprimir, alterar, o manipular cualquier dispositivo de protección o sistema de seguridad que estuviera instalado en el Sitio Web. </p>
        <p>En caso de que el Usuario o tercero considere que cualquiera de los Contenidos del Sitio Web suponga una violación de los derechos de protección de la propiedad intelectual, deberá comunicarlo inmediatamente a <span >Zen-IT</span> a través de los datos de contacto del apartado de INFORMACIÓN GENERAL de este Aviso Legal y Condiciones Generales de Uso. </p>
        <h1 class="container-title-text">VI. Acciones Legales, Legislación Aplicable y Jurisdicción</h1>
        <p>
            <span >Zen-IT</span> se reserva la facultad de presentar las acciones civiles o penales que considere necesarias por la utilización indebida del Sitio Web y Contenidos, o por el incumplimiento de las presentes Condiciones.
        </p>
        <p>La relación entre el Usuario y <span >Zen-IT</span> se regirá por la normativa vigente y de aplicación en el territorio español. De surgir cualquier controversia en relación con la interpretación y/o a la aplicación de estas Condiciones las partes someterán sus conflictos a la jurisdicción ordinaria sometiéndose a los jueces y tribunales que correspondan conforme a derecho. </p>
        <p>Este documento de Aviso Legal y Condiciones Generales de uso del sitio web ha sido creado mediante el generador de <a class="text-body text-decoration-none" href="https://textos-legales.edgartamarit.com/">plantilla de aviso legal y condiciones de uso</a> online el día 21/11/2021. </p>

    </div>


    <div *ngIf="globService.currentCulture == 'en-US'" class="container shadow-outside p-5 border-radius-25 bg-default-light mb-75">

        <h1 class="container-title-text"> I. General Information </h1>
        <p> In compliance with the duty of information provided in Law 34/2002 on Services of the Information Society and Electronic Commerce (LSSI-CE) of July 11, the following general information data on this website: </p>
        <p ng-show="data.claseTitular == 'person'"> The ownership of this website, <strong> www.zen-itc.com </strong>, (hereinafter, Website) is held by: <strong> Christian Kunze </strong>, with NIF: <strong> X-2972819-T </strong>, and whose contact information is: </p>
        <p>
            Address: <strong> Avd. dels Paisos Catalans 28, 2-1, 08380, Malgrat de Mar </strong>
        </p>
        <p>
            Contact phone: <strong> +49 177 289 1560 </strong>
        </p>
        <p>
            Contact email: <strong> contact@zen-itc.com </strong>
        </p>
        <h1 class="container-title-text"> II. General Terms and Conditions of Use </h1>
        <h5> <strong> The purpose of the conditions: The Website </strong> </h5>
        <p> The purpose of these General Conditions of Use (hereinafter, Conditions) is to regulate access and use of the Website. For the purposes of these Conditions, the Website will be understood as: the external appearance of the screen interfaces, both statically and dynamically, that is, the navigation tree; and all the elements integrated both in the screen interfaces and in the navigation tree (hereinafter, Contents) and all those services or online resources that may be offered to Users (hereinafter, Services). </p>
        <p>
            <span> Zen-IT </span> reserves the right to modify, at any time, and without prior notice, the presentation and configuration of the Website and the Contents and Services that may be incorporated into it. The User acknowledges and accepts that at any time <span> Zen-IT </span> may interrupt, deactivate and / or cancel any of these elements that are integrated into the Website or access to them.
        </p>
        <p ng-show="data.accesoLibre == 'Sí'"> Access to the Website by the User is free and, as a general rule, is free without the User having to provide a consideration to be able to enjoy it. , except for the cost of connection through the telecommunications network provided by the access provider that the User has contracted. </p>
        <p ng-show="data.registroLibre == 'Yes'" class=""> The use of any of the Contents or Services of the Website may be done through the subscription or prior registration of the User. </p>
        <h5> <strong> The User </strong> </h5>
        <p> The access, navigation and use of the Website, confers the condition of User, for which reason they accept, from the start of browsing the Website, all the Conditions established herein, as well as their subsequent modifications, without detriment to the application of the corresponding mandatory legal regulations depending on the case. Given the relevance of the foregoing, the User is recommended to read them each time he visits the Website. </p>
        <p> The <span> Zen-IT </span> Website provides a wide variety of information, services and data. The User assumes his responsibility to make a correct use of the Website. This responsibility will extend to: </p>
        <ul>
            <li> A use of the information, Contents and / or Services and data offered by <span> Zen-IT </span> without being contrary to the provisions of these Conditions, the Law, morality or public order, or that in any other way may involve injury to the rights of third parties or the very operation of the Website. </li>
            <li> The veracity and legality of the information provided by the User in the forms issued by <span> Zen-IT </span> to access certain Content or Services offered by the Website. In any case, the User will immediately notify <span> Zen-IT </span> about any fact that allows the improper use of the information registered in said forms, such as, but not only, theft, loss, or unauthorized access to identifiers and / or passwords, in order to proceed with their immediate cancellation. </li>
        </ul>
        <p> Mere access to this Website does not imply any kind of commercial relationship between <span> Zen-IT </span> and the User. </p>
        <p ng-show="data.mayorEdad == 'No'" class=""> Always in compliance with current legislation, this <span> Zen-IT </span> Website is addressed to all people , regardless of their age, that they can access and / or navigate the pages of the Website. </p>
        <h1 class="container-title-text"> III. Access and Navigation on the Website: Exclusion of Guarantees and Responsibility </h1>
        <p>
            <span> Zen-IT </span> does not guarantee the continuity, availability and usefulness of the Website, nor of the Contents or Services. <span> Zen-IT </span> will do everything possible for the proper functioning of the Website, however, it is not responsible or guarantees that access to this Website will not be uninterrupted or error-free.
        </p>
        <p> Neither is it responsible or guarantees that the content or software that can be accessed through this Website is free from error or causes damage to the User's computer system (software and hardware). In no case will <span> Zen-IT </span> be responsible for losses, damages or damages of any kind arising from access, navigation and use of the Website, including, but not limited to, those caused to the computer systems or those caused by the introduction of viruses. </p>
        <p>
            <span> Zen-IT </span> is not responsible for any damages that may be caused to users by improper use of this Website. In particular, it is not responsible in any way for falls, interruptions, lack or defect of telecommunications that may occur.
        </p>
        <h1 class="container-title-text"> IV. Links Policy </h1>
        <p ng-show="data.enlacesTerceros == 'Yes'"> It is reported that the <span> Zen-IT </span> Website puts or can make available means of link (such as, between others, links, banners, buttons), directories and search engines that allow Users to access websites owned and / or managed by third parties. </p>
        <p ng-show="data.enlacesTerceros == 'Yes'"> The installation of these links, directories and search engines on the Website is intended to facilitate Users' search for and access to information available on the Internet , without it being considered a suggestion, recommendation or invitation to visit them. </p>
        <p ng-show="data.Third-party links == 'Yes' & amp; & amp; data.enlacesAffiliation == 'No'">
            <span> Zen-IT </span> does not offer or market by itself or through third parties the products and / or services available on said linked sites.
        </p>
        <p ng-show="data.enlacesTerceros == 'Yes'"> Likewise, it will not guarantee the technical availability, accuracy, veracity, validity or legality of sites outside its property that can be accessed through the links. </p>
        <p ng-show="data.enlacesTerceros == 'Yes'">
            <span> Zen-IT </span> will in no case review or control the content of other websites, nor will it approve, examine or endorse the products and services, content, files and any other material on the aforementioned linked sites. .
        </p>
        <p ng-show="data.enlacesTerceros == 'Yes'">
            <span> Zen-IT </span> does not assume any responsibility for damages that may occur due to the access, use, quality or legality of the contents, communications, opinions, products and services of the websites not managed by <span> Zen-IT </span> and that they are linked on this Website.
        </p>
        <p> The User or third party who makes a hyperlink from a web page of another, different, website to the <span> Zen-IT </span> Website must know that: </p>
        <p> The reproduction - totally or partially - of any of the Contents and / or Services of the Website is not allowed without the express authorization of <span> Zen-IT </span>. </p>
        <p> No false, inaccurate or incorrect manifestation is allowed on the <span> Zen-IT </span> Website, nor on the Contents and / or Services thereof. </p>
        <p> With the exception of the hyperlink, the website on which said hyperlink is established will not contain any element of this Website, protected as intellectual property by the Spanish legal system, unless expressly authorized by <span> Zen-IT </span>. </p>
        <p> The establishment of the hyperlink will not imply the existence of relations between <span> Zen-IT </span> and the owner of the website from which it is made, nor the knowledge and acceptance of <span> Zen-IT </span> of the contents, services and / or activities offered on said website, and vice versa. </p>
        <h1 class="container-title-text"> V. Intellectual and Industrial Property </h1>
        <p>
            <span> Zen-IT </span> by itself or as a transferee, is the owner of all the intellectual and industrial property rights of the Website, as well as the elements contained therein (by way of example and not exhaustive, images , sound, audio, video, software or texts, trademarks or logos, color combinations, structure and design, selection of materials used, computer programs necessary for its operation, access and use, etc.). They will, therefore, be works protected as intellectual property by the Spanish legal system, being applicable both the Spanish and Community regulations in this field, as well as the international treaties related to the matter and signed by Spain.
        </p>
        <p> All rights reserved. By virtue of the provisions of the Intellectual Property Law, the reproduction, distribution and public communication, including the method of making them available, of all or part of the contents of this web page, for commercial purposes, are expressly prohibited. in any support and by any technical means, without the authorization of <span> Zen-IT </span>. </p>
        <p> The User agrees to respect the intellectual and industrial property rights of <span> Zen-IT </span>. You can view the elements of the Website or even print, copy and store them on your computer's hard drive or on any other physical medium as long as it is exclusively for your personal use. The User, however, may not delete, alter, or manipulate any protection device or security system that was installed on the Website. </p>
        <p> In the event that the User or third party considers that any of the Contents of the Website constitutes a violation of the rights of protection of intellectual property, they must immediately notify <span> Zen-IT </span> through the contact details of the GENERAL INFORMATION section of this Legal Notice and General Conditions of Use. </p>
        <h1 class="container-title-text"> VI. Legal Actions, Applicable Legislation and Jurisdiction </h1>
        <p>
            <span> Zen-IT </span> reserves the right to file the civil or criminal actions it deems necessary for the improper use of the Website and Contents, or for the breach of these Conditions.
        </p>
        <p> The relationship between the User and <span> Zen-IT </span> will be governed by current regulations and applicable in the Spanish territory. Should any controversy arise in relation to the interpretation and / or application of these Conditions, the parties will submit their conflicts to ordinary jurisdiction, submitting to the corresponding judges and courts in accordance with the law. </p>
        <p> This document of Legal Notice and General Conditions of use of the website has been created using the generator of <a class="text-body text-decoration-none" href="https: //textos-legales.edgartamarit. com / "> template of legal notice and conditions of use </a> online on 11/21/2021. </p>


    </div>

</section>