import { BrowserModule } from '@angular/platform-browser';
import { NgModule } from '@angular/core';
import { ParallaxDirective } from './parallax.directive';

import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { APP_BASE_HREF } from '@angular/common';
import { HttpClientModule } from '@angular/common/http';
import { RouterModule, UrlSegment} from '@angular/router'; // For internal routing
import { FormsModule, ReactiveFormsModule } from '@angular/forms'; // For Model Binding

import { Main } from './main/main.component';
import { Services } from './services/services.component';
import { SoftwareDevService } from './services/software-dev/software-dev-service.component';
import { ITManagementService } from './services/it-management/it-management-service.component';
import { ITConsultingService } from './services/it-consulting/it-consulting-service.component';
import { Contact } from './contact/contact.component';
import { AboutUs } from './about_us/about-us.component';
import { Privacy } from './privacy/privacy.component';
import { LegalNotice } from './legal-notice/legal-notice.component';
import { Cookies } from './cookies/cookies.component';
import { GlobalizationService } from './shared/globalization/globalizationService';
import { Footer } from './footer/footer.component';


//let routes = [
//    { path: "", component: Main },
//    { path: "services/software-development", component: SoftwareDevService},
//    { path: "services/it-management", component: SoftwareDevService},
//    { path: "services/it-consulting", component: SoftwareDevService },
//    //{ path: "**", component: AppModule }
//];


@NgModule({
  declarations: [
        AppComponent,
        Main,
        Services,
        SoftwareDevService,
        ITManagementService,
        ITConsultingService,
        Contact,
        AboutUs,
        Privacy,
        LegalNotice,
        Cookies,
        ParallaxDirective,
        Footer
  ],
  imports: [
      BrowserModule,
      AppRoutingModule,
      HttpClientModule,
      ReactiveFormsModule,
      //RouterModule.forRoot(routes, {
      //    useHash: false,
      //    enableTracing: false, //For debugging of routes

      //}),
      RouterModule.forRoot([], {
          useHash: false,
          enableTracing: false, //For debugging of routes

      }),
      FormsModule
  ],
    providers: [
        { provide: APP_BASE_HREF, useValue: '/' },
        GlobalizationService
    ],
  bootstrap: [AppComponent]
})
export class AppModule {

    constructor() {

        //routes[1].path = "de-DE/" + routes[1].path;
        //console.log(routes);
    }
}
