

<section class="header h-250 min-h-250">
</section>

<section id="header-section" class="section p-4">



    <!-- German -->
    <div *ngIf="globService.currentCulture == 'de-DE'" class="container shadow-outside p-5 border-radius-25 bg-default-light mb-75">
        
        <h1 class="container-title-text"> Cookie-Richtlinie </h1>
        <p> Der Zugriff auf diese Website kann die Verwendung von Cookies beinhalten. Cookies sind kleine Informationsmengen, die in dem von jedem Benutzer verwendeten Browser gespeichert werden – auf den verschiedenen Geräten, mit denen er möglicherweise navigiert –, damit sich der Server an bestimmte Informationen erinnert, die später und nur der Server, der sie implementiert hat, gelesen werden. Cookies erleichtern die Navigation, machen sie nutzerfreundlicher und beschädigen das Navigationsgerät nicht. </p>
        <p> Cookies sind automatische Verfahren zum Sammeln von Informationen in Bezug auf die vom Benutzer während seines Besuchs auf der Website festgelegten Präferenzen, um ihn als Benutzer zu erkennen und seine Erfahrung und Nutzung der Website zu personalisieren, und können beispielsweise auch durch , helfen, Fehler zu erkennen und zu beheben. </p>
        <p> Die durch Cookies gesammelten Informationen können das Datum und die Uhrzeit der Besuche auf der Website, die aufgerufenen Seiten, die auf der Website verbrachte Zeit und die kurz davor und danach besuchten Websites umfassen. Kein Cookie erlaubt es jedoch, die Telefonnummer des Benutzers oder andere persönliche Kontaktmöglichkeiten zu kontaktieren. Kein Cookie kann Informationen von der Festplatte des Benutzers extrahieren oder persönliche Informationen stehlen. Die privaten Informationen des Benutzers können nur Teil der Cookie-Datei sein, wenn der Benutzer diese Informationen persönlich an den Server weitergibt. </p>
        <p> Cookies, die eine Identifizierung einer Person ermöglichen, gelten als personenbezogene Daten. Daher gilt für sie die oben beschriebene Datenschutzerklärung. In diesem Sinne ist für die Verwendung derselben die Zustimmung des Benutzers erforderlich. Diese Einwilligung wird auf der Grundlage einer authentischen Entscheidung, die durch eine positive und positive Entscheidung angeboten wird, vor der Erstbehandlung mitgeteilt, entfernbar und dokumentiert. </p>
        <div ng-show="hasPersonalCookies == 'Ja'" class="">
            <h5> <strong> Eigene Cookies </strong> </h5>
            <p> Dies sind Cookies, die an den Computer oder das Gerät des Benutzers gesendet und ausschließlich von <span class="blanc-space ng-binding"> Zen-IT </span> verwaltet werden, um das beste Funktionieren der Website zu gewährleisten. Die gesammelten Informationen werden verwendet, um die Qualität der Website und ihres Inhalts sowie Ihre Erfahrung als Benutzer zu verbessern. Diese Cookies ermöglichen es, den Benutzer als wiederkehrenden Besucher der Website zu erkennen und den Inhalt anzupassen, um Inhalte anzubieten, die seinen Vorlieben entsprechen. </p>
        </div>
        <div class="thirdCookies" ng-show="hasThirdCookies == 'Yes'">
            <h5> <strong> Cookies von Drittanbietern </strong> </h5>
            <p> Dies sind Cookies, die von externen Einheiten verwendet und verwaltet werden, die <span class="blanc-space ng-binding"> Zen-IT </span> Dienste bereitstellen, die von ihr angefordert werden, um die Website und die Benutzererfahrung beim Surfen auf der Website zu verbessern . Die Hauptzwecke, für die Cookies von Drittanbietern verwendet werden, bestehen darin, Zugriffsstatistiken zu erhalten und Navigationsinformationen zu analysieren, dh wie der Benutzer mit der Website interagiert. </p>
            <p> Die erhaltenen Informationen beziehen sich beispielsweise auf die Anzahl der besuchten Seiten, die Sprache, den Ort, von dem aus der Benutzer auf die IP-Adresse zugreift, die Anzahl der Benutzer, die darauf zugreifen, die Häufigkeit und Wiederholung der Besuche, den Besuch Uhrzeit, verwendeter Browser, Betreiber oder Gerätetyp, von dem aus der Besuch erfolgt. Diese Informationen werden verwendet, um die Website zu verbessern und neue Bedürfnisse zu erkennen, um Benutzern einen Inhalt und / oder eine Dienstleistung von höchster Qualität anzubieten. In jedem Fall werden die Informationen anonym gesammelt und Trendberichte auf der Website erstellt, ohne einzelne Benutzer zu identifizieren. </p>
            <p> Sie können weitere Informationen zu Cookies, Informationen zum Datenschutz erhalten oder die Beschreibung der Art der verwendeten Cookies, ihrer Hauptmerkmale, Ablauffrist usw. einsehen. im folgenden Link(s): </p>
            <ul>
                <li class="text-wrap ng-binding"> Google Ireland Limited: Gordon House, Barrow Street, Dublin 4, Irland </li>
            </ul>
            <p> Die für die Bereitstellung von Cookies verantwortliche(n) Stelle(n) können diese Informationen an Dritte weitergeben, sofern dies gesetzlich vorgeschrieben ist oder es sich um Dritte handelt, die diese Informationen für diese Unternehmen verarbeiten. < / p>
        </div>
        <h5> <strong> Cookies deaktivieren, ablehnen und löschen </strong> </h5>
        <p> Der Benutzer kann die auf seinem Gerät installierten Cookies - ganz oder teilweise - über seine Browsereinstellungen (darunter zum Beispiel Chrome, Firefox, Safari, Explorer) deaktivieren, ablehnen und löschen. In diesem Sinne können sich die Verfahren zum Ablehnen und Löschen von Cookies von Internetbrowser zu Internetbrowser unterscheiden. Folglich muss der Benutzer die Anweisungen des von ihm verwendeten Internetbrowsers beachten. Falls Sie die Verwendung von Cookies – ganz oder teilweise – ablehnen, können Sie die Website weiterhin nutzen, obwohl Sie möglicherweise einige ihrer Funktionen eingeschränkt nutzen können. </p>
        <p> Dieses Cookie-Richtliniendokument wurde mit den <a class="text-body text-decoration-none" href="https://textos-legales.edgartamarit.com/"> Richtlinienvorlagengenerator-Cookies </a> online am 21.11.2021. </p>
    
    </div>


    <!-- Spanish -->
    <div *ngIf="globService.currentCulture == 'es-ES'" class="container shadow-outside p-5 border-radius-25 bg-default-light mb-75">
        
        <h1 class="container-title-text">Política de Cookies</h1>
        <p>El acceso a este Sitio Web puede implicar la utilización de cookies. Las cookies son pequeñas cantidades de información que se almacenan en el navegador utilizado por cada Usuario —en los distintos dispositivos que pueda utilizar para navegar— para que el servidor recuerde cierta información que posteriormente y únicamente el servidor que la implementó leerá. Las cookies facilitan la navegación, la hacen más amigable, y no dañan el dispositivo de navegación.</p>
        <p>Las cookies son procedimientos automáticos de recogida de información relativa a las preferencias determinadas por el Usuario durante su visita al Sitio Web con el fin de reconocerlo como Usuario, y personalizar su experiencia y el uso del Sitio Web, y pueden también, por ejemplo, ayudar a identificar y resolver errores.</p>
        <p>La información recabada a través de las cookies puede incluir la fecha y hora de visitas al Sitio Web, las páginas visionadas, el tiempo que ha estado en el Sitio Web y los sitios visitados justo antes y después del mismo. Sin embargo, ninguna cookie permite que esta misma pueda contactarse con el número de teléfono del Usuario o con cualquier otro medio de contacto personal. Ninguna cookie puede extraer información del disco duro del Usuario o robar información personal. La única manera de que la información privada del Usuario forme parte del archivo Cookie es que el usuario dé personalmente esa información al servidor.</p>
        <p>Las cookies que permiten identificar a una persona se consideran datos personales. Por tanto, a las mismas les será de aplicación la Política de Privacidad anteriormente descrita. En este sentido, para la utilización de las mismas será necesario el consentimiento del Usuario. Este consentimiento será comunicado, en base a una elección auténtica, ofrecido mediante una decisión afirmativa y positiva, antes del tratamiento inicial, removible y documentado.</p>
        <div ng-show="hasPersonalCookies == 'Sí'" class="">
            <h5><strong>Cookies propias</strong></h5>
            <p>Son aquellas cookies que son enviadas al ordenador o dispositivo del Usuario y gestionadas exclusivamente por <span class="blanc-space ng-binding">Zen-IT</span> para el mejor funcionamiento del Sitio Web. La información que se recaba se emplea para mejorar la calidad del Sitio Web y su Contenido y su experiencia como Usuario. Estas cookies permiten reconocer al Usuario como visitante recurrente del Sitio Web y adaptar el contenido para ofrecerle contenidos que se ajusten a sus preferencias. </p>
        </div>
        <div class="thirdCookies" ng-show="hasThirdCookies == 'Sí'">
            <h5><strong>Cookies de terceros</strong></h5>
            <p>Son cookies utilizadas y gestionadas por entidades externas que proporcionan a <span class="blanc-space ng-binding">Zen-IT</span> servicios solicitados por este mismo para mejorar el Sitio Web y la experiencia del usuario al navegar en el Sitio Web. Los principales objetivos para los que se utilizan cookies de terceros son la obtención de estadísticas de accesos y analizar la información de la navegación, es decir, cómo interactúa el Usuario con el Sitio Web. </p>
            <p>La información que se obtiene se refiere, por ejemplo, al número de páginas visitadas, el idioma, el lugar a la que la dirección IP desde el que accede el Usuario, el número de Usuarios que acceden, la frecuencia y reincidencia de las visitas, el tiempo de visita, el navegador que usan, el operador o tipo de dipositivo desde el que se realiza la visita. Esta información se utiliza para mejorar el Sitio Web, y detectar nuevas necesidades para ofrecer a los Usuarios un Contenido y/o servicio de óptima calidad. En todo caso, la información se recopila de forma anónima y se elaboran informes de tendencias del Sitio Web sin identificar a usuarios individuales.</p>
            <p>Puede obtener más información sobre las cookies, la información sobre la privacidad, o consultar la descripción del tipo de cookies que se utiliza, sus principales características, periodo de expiración, etc. en el siguiente(s) enlace(s):</p>
            <ul>
                <li class="text-wrap ng-binding">Google Ireland Limited: Gordon House, Barrow Street, Dublin 4, Irlanda</li>
	        </ul>
            <p>La(s) entidad(es) encargada(s) del suministro de cookies podrá(n) ceder esta información a terceros, siempre y cuando lo exija la ley o sea un tercero el que procese esta información para dichas entidades.</p>
        </div>
        <h5><strong>Deshabilitar, rechazar y eliminar cookies</strong></h5>
        <p>El Usuario puede deshabilitar, rechazar y eliminar las cookies —total o parcialmente— instaladas en su dispositivo mediante la configuración de su navegador (entre los que se encuentran, por ejemplo, Chrome, Firefox, Safari, Explorer). En este sentido, los procedimientos para rechazar y eliminar las cookies pueden diferir de un navegador de Internet a otro. En consecuencia, el Usuario debe acudir a las instrucciones facilitadas por el propio navegador de Internet que esté utilizando. En el supuesto de que rechace el uso de cookies —total o parcialmente— podrá seguir usando el Sitio Web, si bien podrá tener limitada la utilización de algunas de las prestaciones del mismo.</p>
        <p>Este documento de Política de Cookies ha sido creado mediante el generador de <a class="text-body text-decoration-none" href="https://textos-legales.edgartamarit.com/">plantilla de política de cookies</a> online el día 21/11/2021. </p>
    
    </div>

    <!-- English -->
    <div *ngIf="globService.currentCulture == 'en-US'" class="container shadow-outside p-5 border-radius-25 bg-default-light mb-75">
        <h1 class="container-title-text"> Cookies Policy </h1>
        <p> Access to this Website may involve the use of cookies. Cookies are small amounts of information that are stored in the browser used by each User - in the different devices that they may use to navigate - so that the server remembers certain information that later and only the server that implemented it will read. Cookies facilitate navigation, make it more user-friendly, and do not damage the navigation device. </p>
        <p> Cookies are automatic procedures for collecting information related to the preferences determined by the User during his visit to the Website in order to recognize him as a User, and personalize his experience and use of the Website, and may also, by For example, help identify and resolve errors. </p>
        <p> The information collected through cookies may include the date and time of visits to the Website, the pages viewed, the time spent on the Website and the sites visited just before and after it. However, no cookie allows it to contact the User's phone number or any other means of personal contact. No cookie can extract information from the User's hard drive or steal personal information. The only way that the User's private information is part of the Cookie file is for the user to personally give that information to the server. </p>
        <p> Cookies that allow you to identify a person are considered personal data. Therefore, the Privacy Policy described above will apply to them. In this sense, for the use of the same, the consent of the User will be necessary. This consent will be communicated, based on an authentic choice, offered by an affirmative and positive decision, before the initial treatment, removable and documented. </p>
        <div ng-show="hasPersonalCookies == 'Yes'" class="">
            <h5> <strong> Own cookies </strong> </h5>
            <p> These are cookies that are sent to the User's computer or device and managed exclusively by <span class="blanc-space ng-binding"> Zen-IT </span> for the best functioning of the Website. The information that is collected is used to improve the quality of the Website and its Content and your experience as a User. These cookies allow the User to be recognized as a recurring visitor to the Website and to adapt the content to offer content that meets her preferences. </p>
        </div>
        <div class="thirdCookies" ng-show="hasThirdCookies == 'Yes'">
            <h5> <strong> Third-party cookies </strong> </h5>
            <p> These are cookies used and managed by external entities that provide <span class="blanc-space ng-binding"> Zen-IT </span> services requested by the latter to improve the Website and the user experience when browse the Website. The main objectives for which third-party cookies are used are to obtain access statistics and analyze navigation information, that is, how the User interacts with the Website. </p>
            <p> The information obtained refers, for example, to the number of pages visited, the language, the place where the IP address from which the User accesses, the number of Users who access, the frequency and recurrence of the visits, the visit time, the browser they use, the operator or type of device from which the visit is made. This information is used to improve the Website, and detect new needs to offer Users a Content and / or service of the highest quality. In any case, the information is collected anonymously and trend reports on the Website are prepared without identifying individual users. </p>
            <p> You can obtain more information about cookies, information about privacy, or consult the description of the type of cookies used, their main characteristics, expiration period, etc. in the following link (s): </p>
            <ul>
                <li class="text-wrap ng-binding"> Google Ireland Limited: Gordon House, Barrow Street, Dublin 4, Ireland </li>
            </ul>
            <p> The entity (s) in charge of supplying cookies may transfer this information to third parties, as long as it is required by law or it is a third party that processes this information for said entities. < / p>
        </div>
        <h5> <strong> Disable, reject and delete cookies </strong> </h5>
        <p> The User can disable, reject and delete the cookies - totally or partially - installed on their device through the configuration of their browser (among which are, for example, Chrome, Firefox, Safari, Explorer). In this sense, the procedures for rejecting and deleting cookies may differ from one Internet browser to another. Consequently, the User must refer to the instructions provided by the Internet browser they are using. In the event that you reject the use of cookies - totally or partially - you may continue to use the Website, although you may have limited use of some of its features. </p>
        <p> This Cookie Policy document has been created using the <a class="text-body text-decoration-none" href="https://textos-legales.edgartamarit.com/"> policy template generator of cookies </a> online on 11/21/2021. </p>

    </div>

</section>