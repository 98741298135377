import { Component, OnInit} from '@angular/core';
import { Router } from '@angular/router';
import { ParallaxDirective } from '../parallax.directive';

@Component({
    selector: "services-component",
    templateUrl: "./services.component.html",
    styleUrls: ["./services.component.css"]
})
export class Services {

    constructor(public router: Router,) {

    }
}